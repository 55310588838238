const Authorization = 'Authorization';
const Rol = 'Rol';
const IdRol = 'IdRol';
			let cookie = {};
			document.cookie.split(';').forEach(function(el) {
                let [key,value] = el.split('=');
                cookie[key.trim()] = value;
            })
       
export default { // data
    // access: window.localStorage.getItem('default_auth_token') || null,
    access: cookie[Authorization] || null,
    rol: cookie[Rol] || null,
    IdRol: cookie[IdRol] || null,
    user: window.localStorage.getItem('usuario') || null,
    loader: false
}
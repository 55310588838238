<template>
<div class="landing-container">
    <div id="footer" class="section fondo-footer flex align-items-center flex-column lg:flex-row">
        <div class="col-9 xl:col-9 lg:col-9">
            <div class="grid">
                <!-- <div class="lg:col-3 xl:col-3 col-12">
                    <img id="logo" src="layout/images/seway-blanco.png" alt="ultima-layout" style="height: 3.5rem">
                </div>
                <div class="lg:col-3 xl:col-3 col-12"> -->
                    <router-link to="/">
                    <img id="logo" src="layout/images/setic-blanco.png" alt="ultima-layout" style="height: 4.2rem">
                    </router-link>
                <!-- </div> -->
            </div>
            
            
            <p>Todo un equipo enfocado en satisfacer las<br>
            necesidades y expectativas de nuestros clientes<br><br>

            CLL. 85 # 48-01, Itagüí, Antioquia Central Mayorista<br>
            directorcomercial@setic.co <br>
            (+57) 316 471 1351</p>
        </div>
        

        <div class="col-6 xl:col-3 lg:col-3">
            <img id="logo" src="layout/images/seway-blanco.png" alt="ultima-layout" style="height: 3.5rem">
            <div class="py-5">
                <p class="lg:ml-6 xl:ml-6 ">Siguenos</p>
                <a href="https://www.linkedin.com/company/seticsas" target="_blank" rel="noopener noreferrer"><i class="pi pi-linkedin lg:ml-5 xl:ml-5 buttom-footer-redes" style="color: #EB7D10"></i></a>
                <a href="https://www.instagram.com/seticsas/" target="_blank" rel="noopener noreferrer"><i class="pi pi-instagram lg:mx-3 xl:mx-3 md:mx-1 sm:mx-1 buttom-footer-redes"></i></a>
                <a href="https://www.facebook.com/Direcioncomercial" target="_blank" rel="noopener noreferrer"><i class="pi pi-facebook buttom-footer-redes"></i></a>
            </div>
        </div>
    </div>
</div>

</template>

<script>
</script>

<style lang="scss" scoped>

.buttom-footer-redes{
    font-size: 2rem;
}
.fondo-footer{
    background: linear-gradient(90deg, rgba(2,0,36,1) 25%, rgba(10,53,78,1) 75%, rgba(0,141,170,1) 100%) !important;
}

//moviles
@media only screen and (max-width: 800px) {
   
    .buttom-footer-redes{
        font-size: 2rem;
        margin: 0px 0rem 3rem 1rem;
    }
}
</style>
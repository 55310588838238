// Config witch laravel server
//Desarrollo
// export const APIENDPOINT = "http://127.0.0.1:8000/api/";
// export const APIENDPOINTIMG = "http://127.0.0.1:8000/";
// export const APIFRONT = "http://localhost:8080/#/";

//QA
export const APIENDPOINT = "https://apirestqa.systram.com.co/api/"
export const APIENDPOINTIMG = "https://apirestqa.systram.com.co/"
export const APIFRONT = "https://sewayerpqa.seway.com.co/"

// export const APIENDPOINT = "https://apiresterp-qa.seway.com.co/api/"
// export const APIENDPOINTIMG = "https://apiresterp-qa.seway.com.co/"
// export const APIFRONT = "https://sewayerpqa.seway.com.co/"

// export const APIENDPOINT = "https://apirest-erpqa.seway.com.co/api/"
// export const APIENDPOINTIMG = "https://apirest-erpqa.seway.com.co/"

//DEMO
// export const APIENDPOINT = "https://apirestdemo.systram.com.co/api/"
// export const APIENDPOINTIMG = "https://apirestdemo.systram.com.co/"
// export const APIFRONT = "https://erpdemo.seway.com.co//"

//Produccion
    // export const APIENDPOINT = "https://apirest.systram.com.co/api/"
    // export const APIENDPOINTIMG = "https://apirest.systram.com.co/"
    // export const APIFRONT = "https://erp.seway.com.co/"
// export const APIENDPOINT = "https://apirest-erp.seway.com.co/api/"
// export const APIENDPOINTIMG = "https://apirest-erp.seway.com.co/"
// export const APIFRONT = "https://erp.seway.com.co/"

// export const APIENDPOINT = "https://apirest-erpqa.seway.com.co/api/"
// export const APIENDPOINTIMG = "https://apirest-erpqa.seway.com.co/"

<template>
	<div class="layout-footer flex align-items-center p-2 pl-4 shadow-2">
        <!-- <img id="footer-logo" :src="'layout/images/footer-' + (layoutMode === 'light' ? 'ultima' : 'ultima-dark') + '.svg'" alt="ultima-footer-logo"> -->
		<!-- <template v-if="$route.path != '/select-customer'"> -->
        <img id="footer-logo" :src="'layout/images/logo-footer.png'" alt="ultima-footer-logo" width="180">
		<!-- </template>
		<template v-else>
		<img id="footer-logo" :src="'layout/images/customers/'+cargarimgEmpresa" alt="ultima-footer-logo" width="170">
		</template> -->
		<!-- {{cargarimgEmpresa}}
		{{'aaa'}} -->
        <!-- <Button icon="pi pi-github fs-large" class="p-button-rounded p-button-text p-button-plain" :class="{'ml-auto mr-2': !isRTL, 'ml-2 mr-auto': isRTL}"></Button> -->
        <!-- <Button icon="pi pi-facebook fs-large" class="p-button-rounded p-button-text p-button-plain" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></Button>
        <Button icon="pi pi-twitter fs-large" class="p-button-rounded p-button-text p-button-plain" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></Button> -->
        <!-- <img id="footer-logo" :src="url_img" alt="ultima-footer-logo" width="160" height="50" :class="{'ml-auto mr-2': !isRTL, 'mr-2 mr-auto': isRTL}"> -->

    </div>
</template>

<script>
import EventBus from './event-bus';
	export default {
		name: "AppFooter",
		props: {
			layoutMode: {
				type: String,
				default: 'light'
			}
		},
		data() {
			return {
				url_img: 'layout/images/systram-blanco.png',
			}
		},
		mounted() {
            EventBus.on('show-img-customer', (data) => {
				this.url_img = 'layout/images/customers/'+data;
				// console.log(this.url_img);
            });
			EventBus.on('hidden-img-customer', () => {
				this.url_img = 'layout/images/systram-blanco.png';
				// console.log(this.url_img);
            });
        },
		computed: {
			isRTL() {
				return this.$appState.RTL;
			},
			cargarimgEmpresa(){
				const cookieCustomerImg = 'UrlImgCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				})
				// console.log('result: '+cookie[cookieCustomerImg]);
				return cookie[cookieCustomerImg]
			}
		}
	}
</script>

<style scoped>

</style>
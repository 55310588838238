<template>
	<div class="pages-body accessdenied-page flex flex-column">
		<div class=" p-3 flex justify-content-between flex-row align-items-center">
			<router-link class="topbar-left ml-3 flex" to="/login">
				<div class="logo">
					<!-- <img src="layout/images/logo2x.png" alt=""/> -->
					<!-- <img id="logo" src="layout/images/logo-header.png" alt="ultima-layout" style="height: 3.25rem"> -->
					<!-- <img @click="volverselectcustomer" id="logo" src="layout/images/logo-header.png" alt="ultima-layout" style="height: 3.25rem"> -->
				</div>
			</router-link>
			<!-- <router-link class="topbar-right mr-3 flex" to="/">
				<Button type="button" label="DASHBOARD" class="p-button-text p-button-plain topbar-button"></button>
			</router-link> -->
		</div>

		<div class="align-self-center mt-auto mb-auto">
			<div class="pages-panel card flex flex-column">
				<div class="pages-header px-3 py-1">
					<h2>ACCESO DENEGADO</h2>
				</div>
				<div class="card mt-3 px-6">
					<img src="layout/images/pages/error.png" alt=""  @click="volverlogin"/>
				</div>
				<div class="pages-detail pb-6">Hemos enviado un correo a su email, por favor confirmelo <br> para pasar a aprobación por la empresa de transporte.</div>
				<template v-if="loader==true">
					<i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
				</template>
					<Button id="buttonaccess" type="button" label="REENVIAR EMAIL" class="p-button-primary" @click="resend"></Button>

				<router-link to="/login">
					<Button id="buttonaccess" type="button" label="VOLVER AL LOGIN" class="p-button-text" @click="volverlogin"></Button>
				</router-link>
				<Toast />
			</div>
		</div>
	</div>

</template>

<script>
import axios from 'axios'
	export default {
		data() {
			return {
				loader: false,
			}
		},
		methods: {
			resend(){
				this.loader = true;
				var _that = this;
				var buttonaccess = document.getElementById('buttonaccess');
				buttonaccess.setAttribute("disabled", "");
				// var email = localStorage.getItem('Email');
				var correo =  document.cookie.split(";");
				var email = correo[1].replace(' Email=', '');
				console.log(email);
				axios.post('/email/resend', {email: email}).then((response) => {
                            console.log(response.data.message);
                            _that.$toast.add({severity:'success', summary: 'Reenvio exitoso', detail:response.data.message, life: 5000});
                            _that.loader = false;
							buttonaccess.removeAttribute('disabled');
                            // _that.registro = false;
                            // _that.closeModal();
                            
                        }).catch(function (error) {
                        if (error.response) {
                        // Request made and server responded
                        console.log(error.response.data);
						buttonaccess.removeAttribute('disabled');
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        _that.loader = false;
                        // alert(error.response.data.message);
                        // _that.showError(error.response.data.message);
                        } else if (error.request) {
                            _that.loader = false;
							buttonaccess.removeAttribute('disabled');
                        // The request was made but no response was received
                        // console.log(error.request);
                        } else {
                            _that.loader = false;
							buttonaccess.removeAttribute('disabled');
                        // Something happened in setting up the request that triggered an Error
                        // console.log('Error', error.message);
                        }
                    });
			},
			volverlogin(){
				var _that = this;
				document.cookie="ImgUser=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"; 
				document.cookie="Email=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"; 
				document.cookie="Nombre=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
				document.cookie="NameCustomer=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
				document.cookie="IdCustomer=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
				document.cookie="UrlImgCustomer=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
				document.cookie="IdUsuarioRol=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
				document.cookie="UserAdminCustomer=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
				document.cookie="Rol=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
				document.cookie="IdRol=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
				_that.$store.dispatch('infoNavigation/destroyAccess',null);
				_that.$store.dispatch('infoNavigation/destroyIdRol',null);
				_that.$router.push({ path: '/login' });
			}
		}
	}
</script>

<style scoped>

</style>

<template>
    <template v-if="$route.path === '/'">
        <Spinner v-if="$route.path === '/'" />
    </template>
    <router-view>
        
    </router-view>
</template>
<script>
import Spinner from '../pages/Spinner';

export default {
        data() {
            return {
                
            }
        },
        components: {
            "Spinner" : Spinner,
        }
}
</script>

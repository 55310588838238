<template>
<div class="landing-container">
    <Header />
    <div id="banner-seway" class="flex flex-column">
        
        <div class="grid pt-3 lg-mt-5 container-banner-seway xl:pt-5 lg:pt-5" >
            <div class="col-5 lg:col-5 flex flex-column justify-content-center align-items-center xl:align-items-center" style="padding: 0rem;">
               <img id="mano" src="layout/images/mano.png" alt="ultima-layout" class="ml-5">
            </div>
            <div class="col-7 lg:col-7 flex flex-column justify-content-center ">
                <blockquote class="hr-vertical-banner pr-1"><p class="title-banner">Registros y seguridad<br> al <strong>alcance de tu mano</strong></p></blockquote>
                <div class=" col-8 text-center">
                    <router-link to="/login">
                    <Button label="Iniciar sesión" class="mr-3 mb-3 button-banner rounded" to="../../#/login"/>
                    </router-link>
                    <router-link to="/info-generadores">
                    <!-- <router-link to="/pasarela"> -->
                    <Button label="Registrate" id="section_1" class=" mb-3 button-banner-register rounded" to="../../#/login"/>
                    </router-link>
                </div>
            </div>
        </div>
    </div>

    <div class="flex flex-column" style="background: #E6EBED;">
        
        <div class="grid m-0 pt-4 mt-2">
            <div class="col-7 lg:col-7 flex flex-column align-items-center xl:align-items-center pb-6" id="content-ventajas">
               <p class="title-ventajas text-center mb-6 mt-4">Ventajas que ofrece <strong>Seway Plus ERP</strong></p>
               <div class="mx-8">
                <p class="listado-ventajas mt-3"><i class="pi pi-circle-fill mr-3" style="color: #EB7D10"></i>Tendrás un mayor control administrativo sin emplear más recursos de los necesarios. </p>
               </div>
               
               <hr style="width: 65%; height: 3px;border-width: 2px 0 0 0;    border-top: solid #0A354E;" />
                <div class="mx-8">
                <p class="listado-ventajas"><i class="pi pi-circle-fill mr-3" style="color: #EB7D10"></i>Llevas una gestión de facturación y cartera de los procesos operativos y comerciales. </p>
               </div>
               
               <hr style="width: 65%; height: 3px;border-width: 2px 0 0 0;    border-top: solid #0A354E;" />
                <div class="mx-8">
                <p class="listado-ventajas"><i class="pi pi-circle-fill mr-3" style="color: #EB7D10"></i>Te facilita el trabajo permitiendo tener registros en tiempo real de tus entregas. </p>
               </div>
               
            </div>
            <div class="col-5 lg:col-5" style="padding: 0rem;">
                <img id="img-section-1" src="layout/images/ventajas.png" alt="ultima-layout" class="ml-5 mt-5">
            </div>
        </div>
    </div>

    <div id="section_2" class="flex flex-column container-section-3">
        
        <div class="grid m-0 pt-3 mt-3">
                <div class="col-5 lg:col-5 flex flex-column align-items-center xl:align-items-center" style="padding: 0rem;">
                    <img id="img-section-2" src="layout/images/cel-section2.png" alt="ultima-layout" class="my-5">
                </div>
                <div class="col-7 lg:col-7 flex flex-column align-items-center xl:align-items-center justify-content-between pb-6">
                    <p class="title-ventajas text-center mb-5 mt-5">¿Por qué usar <strong>Seway Plus APP?</strong></p>
                    <div class="mx-8 align-items-center text-center">
                        <p class="listado-ventajas mt-4"><i class="pi pi-circle-fill mr-3" style="color: #EB7D10"></i>Ahora los conductores de tu empresa podrán controlar sus anticipos
                        y registrar sus gastos de viaje directamente en una aplicación Móviles que se enlaza con tu software administrativo.</p>
                    </div>
                    <hr style="width: 65%; height: 3px;border-width: 2px 0 0 0;    border-top: solid #0A354E;" />
                    <div class="mx-8 align-items-center text-center">
                        <p class="listado-ventajas"><i class="pi pi-circle-fill mr-3" style="color: #EB7D10"></i>Con Seway tendrás la posibilidad de evaluar la gestión de entrega al
                     comparar ubicación geográfica del destinatario con el sitio de entrega registrado por el conductor.</p>
                    </div>
                    <hr style="width: 65%; height: 3px;border-width: 2px 0 0 0;    border-top: solid #0A354E;" id="section_3" />
                    <div class="mx-8 align-items-center text-center">
                        <p class="listado-ventajas"><i class="pi pi-circle-fill mr-3" style="color: #EB7D10"></i>Optimiza el proceso de registro de gastos, pasando de un registro
                        manual por parte de un auxiliar a un registro en tiempo real por parte del conductor.</p>
                    </div>
                    <hr style="width: 65%; height: 3px;border-width: 2px 0 0 0;    border-top: solid #0A354E;" />
                    <div class="mx-8 align-items-center text-center">
                        <p class="listado-ventajas"><i class="pi pi-circle-fill mr-3" style="color: #EB7D10"></i>Manejas fácilmente la información de documentos firmados, motivos de
                        rechazo e información sobre recogidas y entregas de mercancía, todos los procesos de tu negocio se agilizan y se hacen más seguros.</p>
                    </div>
                    
                </div>
                <div class="col-12 lg:col-6 flex flex-column align-items-center xl:align-items-center pb-6 my-5">
                <p class="title-quienes-somos text-center mb-5 my-5">Quiénes <strong style="color: #EB7D10">somos</strong></p>
                <div class="description-quienes-somos mx-5">
                    <p class="description-quienes-somos ml-5 mb-5">
                        SETIC S.A.S es una empresa dedicada a proveer soluciones
                        integrales de Desarrollo de Software, Aplicativos Móviles
                        y Mantenimiento de Hardware; que permitan a nuestros clientes
                        alcanzar sus objetivos de negocios, a través de productos
                        y servicios de alto grado de calidad, confiabilidad year
                        eficiencia; apoyados en la innovación tecnológica permanente
                        para nuestros procesos.
                    </p>
                </div>
               
                
                </div>
                <div class="col-12 lg:col-6 flex flex-column justify-content-center pb-6 lg:my-5 xl:my-5 xm:my-2 sm:my-2  grid">
                    <blockquote class="hr-vertical-quienes-somos  grid  pr-1">
                        <div class="col-12 lg:col-4 text-center">
                            <img id="img-section-3" src="layout/images/logistico.png" alt="ultima-layout" class="" style="width: 10rem;">
                            <p class="mt-3 quienes-somos-item">Desarrollo en el <br> <strong>sector logístico</strong></p>
                        </div>
                        <div class="col-12 lg:col-4 text-center">
                            <img id="img-section-3" src="layout/images/administrativa.png" alt="ultima-layout" class="" style="width: 10rem;">
                            <p class="mt-3 quienes-somos-item">Desarrollo para la  <br> <strong>gestión administrativa</strong></p>
                        </div>
                        <div class="col-12 lg:col-4 text-center">
                            <img id="img-section-3" src="layout/images/personalizada.png" alt="ultima-layout" class="" style="width: 10rem;">
                            <p class="mt-3 quienes-somos-item">Desarrollo para la  <br> <strong>apps personalizadas</strong></p>
                        </div>
                    </blockquote>
                    <p id="contacto"></p>
                </div>
                
        </div>
       
    </div>

    <div class="section flex flex-column align-items-center section-precios">
        <p class="titulo-precios p-0">Contactanos</p>
            <hr style="width: 100%; height: 5px;border-width: 2px 0 0 0;" />

        <div class="pages-panel">
            <div class="card grid">
                <div class="left-panel lg:col-5 md:col-5 sm:col-12">
                
                <h4 class="mb-3"><strong>Contactarnos</strong></h4>
                    <p class="mb-3">Ingresa la siguiente información...</p>
                    <div class="grid p-fluid">
                         <div class="col-12">
                            <InputText :class="{'p-invalid': submitted && !frmCorreo.nombre, 'contact-input mb-3': true}" v-model="frmCorreo.nombre" placeholder="Ingresa tu Nombre" />
                            <InputText :class="{'p-invalid': submitted && !frmCorreo.email, 'contact-input mb-3': true}" v-model="frmCorreo.email" placeholder="Ingresa tu E-mail" />
                            <InputMask :class="{'p-invalid': submitted && !frmCorreo.celular, 'contact-input mb-3': true}" v-model="frmCorreo.celular" mask="999-999-9999" placeholder="Ingresa tu Número" />
                            <Textarea :class="{'p-invalid': submitted && !frmCorreo.descripcion, 'contact-input contact-message mb-2': true}" v-model="frmCorreo.descripcion" rows="4" placeholder="Ingresa tu Mensaje"></Textarea>
                            <Button class="mb-3 button-contact" :loading="loader" @click="enviarcorreo" label="ENVIAR"></Button>
                        </div>
                    </div>
                   
                    
                    
                    
                    
                </div>
                <div class="right-panel lg:col-7 md:col-7 sm:col-12 mx-auto">
                    <div class="grid p-5">
                        <div class="align-items-center text-center justify-content-center col-12">
                            <img class="align-items-center text-center justify-content-center" id="footer-logo" @click="go_setic" :src="'layout/images/logo-footer.png'" alt="ultima-footer-logo" width="250">
                        </div>
                        
                        <div class="md:col-6 md:col-6 md:col-6 sm-6 sm:col-6 align-items-center text-center container-precios cursor-pointer" @click="go_maps">
                            <i class="pi pi-map-marker fs-xlarge orange-color mt-8"></i>
                            <p class="title my-3"><strong>Nuestra Oficina</strong></p>
                            <p>CLL. 85 # 48-01, Itagüí, Antioquia Central Mayorista</p>
                        </div>
                        <div class="md:col-6 md:col-6 md:col-6 sm-6 sm:col-6 align-items-center text-center container-precios cursor-pointer" @click="go_wpp">
                            <i class="pi pi-whatsapp fs-xlarge orange-color mt-8"></i>
                            <p class="title my-3"><strong>Nuestro Número</strong></p>
                            <p>316-471-1351</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div id="video" class="section flex flex-column align-items-center justify-content-center">
        <p class="title-marcas-confian mb-5">Marcas que <strong>confían en nosotros</strong></p>
        <div class="m-5">
        </div>
    </div>
<Toast />
    <Footer />
</div>

</template>

<script>
import Header from '../landing/Header';
import Footer from '../landing/Footer';
// import Header from '../landing/Header.vue';
export default {
    data() {
        return {
            isMenuActive: false,
            nestedMenuitems: this.$primevue.config.menu,
            loader: false,
            submitted: false,
            frmCorreo: {
                nombre: '',
                email: '',
                celular: '',
                descripcion: '',
            }
        }
    },
    mounted(){
        // console.log(screen.width);
    },
    methods:  {
        enviarcorreo(){
            console.log(this.frmCorreo);
            if (!this.frmCorreo.nombre || !this.frmCorreo.email || !this.frmCorreo.celular || !this.frmCorreo.descripcion) {
                this.submitted = true;
                this.$toast.add({severity:'warn', summary: "Información incompleta", detail:"Ingrese toda la información solicitada", life: 4000});
            }else{
                var _that = this;
                this.loader=true;
                    // const asunto = 'Solicitud de contacto SEWAY-PLUS ERP';
                    api.call('post', 'enviarcorreosolicitud',{nombre: this.frmCorreo.nombre,email: this.frmCorreo.email,celular: this.frmCorreo.celular,descripcion: this.frmCorreo.descripcion}).then(function(response){
                        if(response.status == 200){
                            _that.loader = false;
                            // console.log(response.data.data);
                            _that.$toast.add({severity:'success', summary: "Se envio tu mensaje con exito", detail:"Pronto te contactaremos", life: 4000});
                            _that.loader=false;
                        }
                    }).catch(function (err) {
                        console.log(err);
                        _that.loader = false;
                        this.$toast.add({severity:'error', summary: "Ocurrio un error", detail:"Intenta en unos minutos", life: 4000});
                    });
                // this.$toast.add({severity:'success', summary: "Se envio tu mensaje con exito", detail:"Pronto te contactaremos", life: 4000});
            }
            // this.$toast.add({severity:'success', summary: "Se quito la factura con exito", detail:"", life: 4000});
            // this.loader = true;
        },
        go_maps(){
            window.open('https://goo.gl/maps/ATyzhzgUjamSNZ2V7', '_blank');
        },
        go_wpp(){
            window.open('https://api.whatsapp.com/send/?phone=573164711351', '_blank');
        },
        go_setic(){
            window.open('https://web.setic.co/', '_blank');
        },
        onMenuButtonClick(e) {
            let menu = document.getElementById('menu');

            if (this.isMenuActive) {
                this.addClass(menu, 'fadeOutUp');
                this.isMenuActive = false;
            }
            else {
                this.addClass(menu, 'menu-active fadeInDown');
                this.isMenuActive = true;
            }
            e.preventDefault();
        },

        smoothScroll(id){
            document.querySelector(id).scrollIntoView({
                behavior: 'smooth'
            });
        },
        resolution_pantallas(){
            // console.log(screen.width);
            return screen.width;
        },
        toggleMenu(event) {
				this.$refs.menu.toggle(event);
			},

        addClass(element, classNames) {
            let classNamesArr = classNames.split(' ');
            for (var i = 0; i < classNamesArr.length; i++) {
                let className = classNamesArr[i];
                if (element.classList)
                    element.classList.add(className);
                else
                    element.className += ' ' + className;
            }
        },

        removeClass(element, classNames) {
            let classNamesArr = classNames.split(' ');
            for (var i = 0; i < classNamesArr.length; i++) {
                let className = classNamesArr[i];
                if (element.classList)
                    element.classList.remove(className);
                else
                    element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }
    },
    components: {
            "Footer": Footer,
            "Header": Header
    }
}
</script>

<style lang="scss" scoped>
.videoFrame{
    width: 560px;
    height: 300px;
    
    @media screen and (max-width: 960px) {
        width: 300px;
    }
}
body{
    font-family: 'Montserrat'
}
#btn-registrar{
    color: #0A354E;
    background: #ffffff;
}
#img-section-1{
    width: 28rem;
    float: right;
}
#img-section-2{
    width: 20rem;
    float: right;
    margin-left: 12rem !important;
}
.header-landing-seway{
    position: fixed;
    z-index: 1000;
    border-bottom: 3px solid #FFFFFF;
    border-radius: 0px 0px 5px 5px;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(255,255,255,1);
    -moz-box-shadow: 0px 0px 5px 1px rgba(255,255,255,1);
    box-shadow: 0px 0px 5px 1px rgba(255,255,255,1);
}
.p-menubar {
  padding: 0.75rem;
  background: transparent;
//   color: rgba(255, 255, 255, 0.87);
  border: 1px transparent;
  border-radius: 4px;
}
.container-banner-seway{
    margin-right: 0rem;
    background: url(https://erp.seway.com.co/layout/images/fondo-banner.png);
    // background: url(http://localhost:8080/layout/images/fondo-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 8rem !important;
}
.container-section-3{
    background: url(https://erp.seway.com.co/layout/images/fondo-seccion-3.png);
    // background: url(http://localhost:8080/layout/images/fondo-seccion-3.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.title-marcas-confian{
    color: #0A354E;
    font-size: 2.5rem;
    line-height: normal;
}
.title-banner{
    color: #0A354E;
    font-size: 3.5rem;
    line-height: normal;
}
.hr-vertical-banner{
    border-left: 4px solid #EB7D10;
}
.hr-vertical-quienes-somos{
    border-left: 4px solid #0A354E;
    padding-left: 3rem !important;
}
.quienes-somos-item{
    color: #0A354E;
    font-size: 1.20rem;
    font-weight: 400;
}
.title-ventajas{
    color: #0A354E;
    font-size: 2.25rem;
    line-height: normal;
}
.title-quienes-somos{
    color: #0A354E;
    font-size: 2.5rem;
    font-weight: 800;
}
.description-quienes-somos{
    font-size: 1.5rem;
    text-align: justify
}
.container-list-ventajas{
    line-height: 3em;
}
.listado-ventajas{
    color: #0A354E;
    font-size: 1.3rem;
    line-height: 0.5;
    text-align: justify;
    line-height: normal;
}
.buttom-footer-redes{
    font-size: 2rem;
}
.listado-usos{
    color: #0A354E;
    font-size: 1.3rem;
    line-height: 0.5;
}
.section-precios{
    background: linear-gradient(90deg, rgba(0,117,142,1) 6%, rgba(10,53,78,1) 43%, rgba(2,0,36,1) 84%);
    // background: #0A354E;
}
.titulo-precios{
    color: white;
    font-size: 2rem;
    font-weight: 800;
}
.titulo-plan-precios{
    color: #EB7D10;
    font-size: 2.5rem;
    font-weight: 800;
}
.precio-plan-precios{
    color: #EB7D10;
    // font-size: 2.5rem;
    // font-weight: 800;
}
.buttom-obtener{
    background: #EB7D10;
    color: white;
    width: 50%;
}
.buttom-obtener:hover{
    background: #ffffff;
    color: #0A354E;
    width: 50%;
}
.button-banner{
    font-weight: 800;
    background: #EB7D10;
    color: white;
    font-size: 1.5rem;
}
.button-contact{
    font-weight: 800;
    background: #EB7D10;
    color: white;
}
.button-contact:hover{
    font-weight: 800;
    transition: 0.5s;
    background: linear-gradient(90deg, rgba(2,0,36,1) 25%, rgba(10,53,78,1) 75%, rgba(0,141,170,1) 100%);
    color: white;
}
.button-banner-register{
    font-weight: 800;
    background: #eb7e1000;
    border: #EB7D10 solid;
    color: #EB7D10;
    font-size: 1.2rem;
}
.button-menu-seway{
    background: #EB7D10;
    color: white;
}
.button-menu-seway:hover{
    background: #0A354E;
    color: white;
}
.button-banner:hover{
    font-weight: 800;
    background: #0A354E;
    color: white;
    font-size: 1.5rem;
}
.button-banner-register:hover{
    font-weight: 800;
    background: #0A354E;
    color: white;
    font-size: 1.2rem;
}
.container-precios{
    // border: 1px solid #EB7D10;
    border-radius: 15px;
    // height: 100%;
}
.container-precios:hover{
    transition: 0.5s;
    -webkit-box-shadow: 0px 0px 42px 4px rgba(235,125,16,1);
    -moz-box-shadow: 0px 0px 42px 4px rgba(235,125,16,1);
    box-shadow: 0px 0px 42px 4px rgba(235,125,16,1);
}
#mano{
        width: 19rem;
    }
//moviles
@media only screen and (max-width: 800px) {
    .title-banner{
        color: #0A354E;
        font-size: 1.5rem;
        line-height: normal;
        
    }
    .hr-vertical-banner{
        border-left: 4px solid #EB7D10;
        margin-left: 20%;
    }
    #mano{
        width: 9rem;
    }
    .button-banner{
        font-weight: 200;
        background: #EB7D10;
        color: white;
        font-size: 1rem;
        margin-left: 17px;
    }
    .button-banner:hover{
        font-weight: 200;
        background: #0A354E;
        color: white;
        font-size: 1rem;
        margin-left: 17px;
    }
    .landing-container #header .header-menu-container {
        z-index: 100;
        position: fixed;
        top: 0;
        left: 0;
        // width: 73%;
        background: #212121;
        padding: 2rem 1rem;
    }
    #img-section-1{
        width: 10rem;
        float: right;
    }
    #img-section-2{
        width: 8rem;
        float: right;
        margin-left: 1rem !important;
    }
    .title-ventajas{
        color: #0A354E;
        font-size: 1.5rem;
        line-height: normal;
    }
    .listado-ventajas{
        color: #0A354E;
        font-size: 1rem;
        line-height: 1;
    }
    .container-banner-seway{
        margin-right: 0rem;
        margin-top: 2rem !important;
    }
    .hr-vertical-quienes-somos{
        border-left: 4px solid #0A354E;
        padding-left: 0rem !important;
    }
    #content-ventajas{
        padding-left: 4%;
    }
    .buttom-footer-redes{
        font-size: 2rem;
        margin: 0px 0rem 3rem 1rem;
    }
}
</style>
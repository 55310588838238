export default { // methods
    getAccess(context,data){
        // window.localStorage.setItem('default_auth_token', data);
        document.cookie = "Authorization=Bearer " + data;
        context.commit('updateAccess', data);
    },
    getRol(context,data){
        // window.localStorage.setItem('default_auth_token', data);
        document.cookie = "Rol=" + data;
        context.commit('updateRol', data);
    },
    getIdRol(context,data){
        // window.localStorage.setItem('default_auth_token', data);
        document.cookie = "IdRol=" + data;
        context.commit('updateIdRol', data);
    },
    getUser(context,data){
        window.localStorage.setItem('usuario', JSON.stringify(data));
        context.commit('updateUser', data);
    },
    destroyAccess(context,data){
        // window.localStorage.removeItem('default_auth_token');
        document.cookie="Authorization=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"; 
        context.commit('updateAccess', data);
    },
    destroyIdRol(context,data){
        // window.localStorage.removeItem('default_auth_token');
        document.cookie="IdRol=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"; 
        context.commit('updateIdRol', data);
    },
    destroyUser(context,data){
        window.localStorage.removeItem('usuario');
        context.commit('updateUser', data);
    }
}
<template>
	<div class="pages-body accessdenied-page flex flex-column">
		<div class="topbar p-3 flex justify-content-between flex-row align-items-center">
			<!-- <router-link class="topbar-left ml-3 flex" to="/login">
				<div class="logo">
					<img src="layout/images/logo2x.png" alt=""/>
				</div>
			</router-link> -->
			<!-- <router-link class="topbar-right mr-3 flex" to="/">
				<Button type="button" label="DASHBOARD" class="p-button-text p-button-plain topbar-button"></button>
			</router-link> -->
		</div>

		<div class="align-self-center mt-auto mb-auto">
			<div class="pages-panel card flex flex-column">
				<template v-if="error_verify==true">
					<div class="pages-header px-3 py-1" style="background: rgb(197 69 69);">
					<h2>ERROR al momento verificar</h2>
					</div>
				</template>
				<template v-else>
					<template v-if="loader==true">
						<div class="pages-header px-3 py-1" style="background: rgb(198 201 39);">
						<h2>Verificando...</h2>
						</div>
					</template>
					<template v-else>
						<div class="pages-header px-3 py-1" style="background: #47c545">
						<h2>EMAIL VERIFICADO</h2>
						</div>
					</template>
				</template>
				<div class="card mt-3 px-6">
					<img src="layout/images/pages/error.png" alt=""/>
				</div>
				<template v-if="error_verify==true">
					<div class="pages-detail pb-6">Por favor intenta presionando la tecla F5 o refrescando la pantalla.</div>
				</template>
				<template v-else>
					<div class="pages-detail pb-6">Tu email ha sido verificado correctamente.</div>
				</template>
				<router-link to="/login">
					<Button id="buttonvolver" type="button" label="VOLVER AL LOGIN" class="p-button-raised p-button-primary"></Button>
					<!-- <Button id="buttonvolver" type="button" label="VOLVER AL LOGIN" class="p-button-text"></Button> -->
				</router-link>
				<template v-if="loader==true">
					<i class="pi pi-spin pi-spinner mt-3" style="font-size: 2rem"></i>
				</template>
				<Dialog header="Verificar Email" v-model:visible="display" :breakpoints="{'960px': '85vw'}" :style="{width: '30vw'}" :modal="true">
					<p>Por favor Clicken verificar</p>
					<div class="grid formgrid">
						<template v-if="loader==true">
							<i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
						</template>
					</div>
					<template #footer>
						<Button label="Verificar" @click="verificarCorreo" icon="pi pi-check" class="p-button-secondary"/>
					</template>
				</Dialog>
				<Toast />
			</div>
		</div>
	</div>

</template>

<script>
import axios from 'axios'
// use(VueSweetalert2);
export default {
	data() {
		return {
			haytoken: '',
			display: false,
			error_verify: false,
			loader: false
		}
	},
	mounted(){
		this.abrirmensaje();
	},
	methods: {
		abrirmensaje(){
			var urlCompleta = this.$route.query.url+'&signature='+this.$route.query.signature;
			// console.log(urlCompleta);
			this.verificarCorreo(urlCompleta);
			// this.display = false;
		},
		openModal() {
			this.display = true;
		},
		async verificarCorreo(url){
			var buttonvolver = document.getElementById('buttonvolver');
			buttonvolver.setAttribute("disabled", "");
			var _that = this;
			console.log(url);
			this.loader = true;
			this.error_verify = false;
			// console.log(this.$route);
			// var access = localStorage.getItem('Authorization');
			// var correo =  document.cookie.split(";");
			// console.log(correo);
			const access = this.$store.getters['infoNavigation/access'];
			axios.get(url, {
				headers: {
					'Authorization': `Bearer ${access}`
				}
			}).then((response) => {
				console.log(response.data.data);
            	_that.$toast.add({severity:'success', summary: 'Verificación completada', detail:response.data.data, life: 6000});
				buttonvolver.removeAttribute('disabled');
				_that.loader = false;

			}).catch(function (error) {
			if (error.response) {
				buttonvolver.removeAttribute('disabled');
				_that.loader = false;
				_that.error_verify = true;
			// // Request made and server responded
				// alert(error.response.data.message);
            _that.$toast.add({severity:'error', summary: 'Error de Verificación', detail:error.response.data.message, life: 6000});
			console.log(error.response.data.message);
			}

			});
		},
	},
	components:{

	}
}
</script>

<style scoped>
</style>

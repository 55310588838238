import {createRouter, createWebHashHistory} from 'vue-router';
//CONTENEDORES
import AppWrapper from './AppWrapper.vue'

//HOME
const Home = () => import('./pages/Home.vue')

//info-roles
const InfoEspeciales = () => import('./views/info_roles/Especiales.vue')
const InfoGenerador = () => import('./views/info_roles/Generador.vue')
const InfoProveedor = () => import('./views/info_roles/Proveedor.vue')
const InfoTerceros = () => import('./views/info_roles/Terceros.vue')
const InfoTransporte = () => import('./views/info_roles/Transporte.vue')

//ESPECIALES
const AppEspeciales = () => import('./AppEspecial.vue')
const SelectempresaEspeciales = () => import('./views/especiales/Selectempresa.vue')

//GENERADOR
const SelectempresaGenerador = () => import('./views/generador/Selectempresa.vue')
const PendientesGenerador = () => import('./views/generador/pendientes.vue')
const VentasGenerador = () => import('./views/generador/ventas.vue')
const MovTransporte = () => import('./views/generador/MovTransporte.vue')
const InfoDetalles = () => import('./views/generador/InfoDetalles.vue')
    //EPAYCO
    const Respuesta = () => import('./views/generador/respuestas_epayco/Respuesta.vue')
    

//EMPRESAS DE TRANSPORTE
const SelectempresaEmpresaTransporte = () => import('./views/EmpresaTransporte/Selectempresa.vue')
const PendientesEmpresaTransporte = () => import('./views/EmpresaTransporte/pendientes.vue')
const ConfiguracionET = () => import('./views/EmpresaTransporte/configuracion.vue')


const Inicio = () => import('./components/Inicio.vue')
const routes = [
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/info-especiales',
        name: 'info-especiales',
        component: InfoEspeciales,
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/info-generadores',
        name: 'info-generadores',
        component: InfoGenerador,
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/info-proveedores',
        name: 'info-proveedores',
        component: InfoProveedor,
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/info-terceros',
        name: 'info-terceros',
        component: InfoTerceros,
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/info-transporte',
        name: 'info-transporte',
        component: InfoTransporte,
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/',
        redirect: '/home',
        name: 'spinner',
        component: () => import('./pages/Spinner.vue'),
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue'),
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/pendiente-activacion',
        name: 'pendiente-activacion',
        component: () => import('./pages/PendienteActivacion.vue')
    },
    {
        path: '/pasarela',
        name: 'pasarela',
        component: () => import('./pages/PasarelaRegistro.vue')
    },
    {
        path: '/registrar-servicios-especiales',
        name: 'servicios-especiales',
        component: () => import('./registros/RegistroEspeciales.vue')
    },
    {
        path: '/registrar-generadores',
        name: 'generadores-registro',
        component: () => import('./registros/RegistroGeneradores.vue')
    },
    {
        path: '/registrar-empresas-transporte',
        name: 'empresas-transporte-register',
        component: () => import('./registros/RegistroEmpresas.vue')
    },
    {
        path: '/resetpassword',
        name: 'resetpassword',
        component: () => import('./pages/ResetPaswword.vue')
    },
    {
        path: '/consulta-documento-cliente',
        name: 'consulta-documento-cliente',
        component: () => import('./components/ConsultaDocumentoCliente.vue')
    },
    {
        path: '/respuesta-epayco',
        name: 'respuesta-epayco',
        component: () => Respuesta
    },
    {
        path: '/emailverify',
        name: 'EmailVerified',
        component: () => import('./pages/EmailVerified.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/especiales',
        redirect: '/especiales/select-customer',
        name: 'Especiales',
        component: AppWrapper,
        meta: {
          requiresAuth: true,
        },
        children: [
            {
                path: 'select-customer',
                name: 'select-customer',
                component: SelectempresaEspeciales
            },
            {
                path: 'inicio',
                name: 'inicio',
                component: Inicio
            },
            {
                path: 'editar-perfil',
                name: 'editperfil_especiales',
                component: () => import('./components/EditPerfil.vue')
            },
            {
                path: 'centros-costos',
                name: 'centros-costos',
                component: () => import('./maestros/CentrosCostos.vue')
            },
            {
                path: 'origenes-destinos',
                name: 'origenes-destinos',
                component: () => import('./maestros/OrigenesDestinos.vue')
            },
            {
                path: 'funcionarios',
                name: 'funcionarios',
                component: () => import('./maestros/Funcionarios.vue')
            },
            {
                path: 'solicitudes-transporte-especial',
                name: 'solicitudes-transporte-especial',
                component: () => import('./servicios/SolicitudesEspeciales.vue')
            },
            {
                path: 'historicos-solicitudes-transporte-especial',
                name: 'historicos-solicitudes-transporte-especial',
                component: () => import('./servicios/HistoricosSolicitudesEspeciales.vue')
            }
        ]
    },
    {
        path: '/generador',
        redirect: '/generador/inicio',
        name: 'Generador',
        component: AppWrapper,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'inicio',
            name: 'inicio-generador',
            component: SelectempresaGenerador
          },
          {
            path: 'pendientes',
            name: 'pendientes-generador',
            component: PendientesGenerador
          },
          {
            path: 'ventas',
            name: 'ventas-generador',
            component: VentasGenerador
          },
          {
            path: 'editar-perfil',
            name: 'editperfil_generador',
            component: () => import('./components/EditPerfil.vue')
           },
           {
            path: 'mov-transporte',
            name: 'mov-transporte',
            component: MovTransporte
           },
           {
            path: 'info-detalles',
            name: 'info-detalles',
            component: InfoDetalles
           }
        ]
      },
      {
        path: '/empresa-transporte',
        redirect: '/empresa-transporte/inicio',
        name: 'EmpresaTransporte',
        component: AppWrapper,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'inicio',
            name: 'inicio-empresa-transporte',
            component: SelectempresaEmpresaTransporte
          },
          {
            path: 'pendientes',
            name: 'pendientes-empresa-transporte',
            component: PendientesEmpresaTransporte
          },
          {
            path: 'configuracion',
            name: 'configuracion',
            component: ConfiguracionET
          },
          {
            path: 'editar-perfil',
            name: 'editperfil_empresa',
            component: () => import('./components/EditPerfil.vue')
           }
        ]
      },
    { 
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('./pages/NotFound.vue') 
    },
    { 
        path: '/prueba-login',
        name: 'prueba-login',
        component: () => import('./pages/prueba_login.vue') 
    },
];


const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

export default router;

import axios from 'axios'
import EventBus from '../event-bus';
class Api {
    constructor() { }

    call(requestType, url, data = null) {
        return new Promise((resolve, reject) => {
            axios[requestType](url, data)
                .then(response => {
                    resolve(response);
                })
                .catch(({ response }) => {
                    if (response.data) {
                        if (response.data.message=='Unauthenticated.') {
                            console.log("errorrr");
                            EventBus.emit('sistemalogout');
                        }
                    }
                    
                    reject(response);
                });
        });
    }
}

export default Api;
<template>
<div class="landing-container">
    <Header />

    <div id="banner-seway" class="flex flex-column">
        
        <div class="grid pt-3 lg-mt-5 container-banner-seway" style="margin-right: 0rem;">
            <div class="col-12 lg:col-7 xl:col-7 flex flex-column justify-content-center align-items-center xl:align-items-center mb-5 pb-8">
                <Pasarela />
                <!-- <p class="title-banner"><strong>Generadores de carga</strong></p>
                <p class="description-banner mt-3 text-center">Es la persona (natural o jurídica) que tiene el derecho sobre la <br>
                    mercancía y quien requiere el servicio de transporte</p>
                    <div class="grid my-5">
                            <router-link class="layout-topbar-logo" to="/login">
                            <Button label="Soy generador" class=" mb-3 button-banner-1 rounded-pill mr-3" to="/login"/>
                            </router-link>
                            <router-link class="layout-topbar-logo" to="/registrar-generadores">
                            <Button label="Registrarme" class=" mb-3 button-banner-2 rounded-pill" to="/login"/>
                            </router-link>
                    </div> -->
                    
               <!-- <img id="mano" src="layout/images/mano.png" alt="ultima-layout" class="ml-5"> -->
            </div>
            <div class="col-5 lg:col-5 flex flex-column justify-content-center ">
                <!-- <blockquote class="hr-vertical-banner pr-1"><p class="title-banner">Información<br> <strong>Generadores</strong></p></blockquote>
                <div class=" col-8 text-center">
                    <router-link to="/registrar-generadores">
                    <Button label="Registrarse" class=" mb-3 button-banner rounded" to="../../#/login"/>
                    </router-link>
                </div> -->
            </div>
        </div>
    </div>
    <div id="section_1" class="flex flex-column" style="background: #E6EBED;">
        
        <div class="grid m-0">
            <div class="col-12 lg:col-6 xl:col-6 flex flex-column align-items-center xl:align-items-center pb-6" id="content-ventajas">
               <p class="title-ventajas text-center mb-6 mt-4">Ventajas como <strong>Generador</strong></p>
               <p class="listado-ventajas mt-3"><i class="pi pi-circle-fill mr-3" style="color: #EB7D10"></i><u>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam</u></p>
               <hr style="width: 65%; height: 3px;border-width: 2px 0 0 0;    border-top: solid #0A354E;" />
               <p class="listado-ventajas"><i class="pi pi-circle-fill mr-3" style="color: #EB7D10"></i><u>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam</u></p>
               <hr style="width: 65%; height: 3px;border-width: 2px 0 0 0;    border-top: solid #0A354E;" />
               <p class="listado-ventajas"><i class="pi pi-circle-fill mr-3" style="color: #EB7D10"></i><u>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam</u></p>
               <hr style="width: 65%; height: 3px;border-width: 2px 0 0 0;    border-top: solid #0A354E;" />
               <p class="listado-ventajas"><i class="pi pi-circle-fill mr-3" style="color: #EB7D10"></i><u>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam</u></p>
            </div>
            <div class="col-6 lg:col-6 fondo-ventajas" style="padding: 0rem;">
                <!-- <img id="img-section-1" src="layout/images/ventajas-generador.png" alt="ultima-layout" class="ml-5 mt-5"> -->
            </div>
        </div>
    </div>

    

    <Footer />
        </div>

</template>

<script>
import Footer from '../../landing/Footer';
import Header from '../../landing/Header';
import Pasarela from '../../pages/PasarelaRegistro';
export default {
    data() {
        return {
            isMenuActive: false,
            nestedMenuitems: this.$primevue.config.menu
        }
    },
    mounted(){
        // console.log(screen.width);
    },
    methods:  {
        onMenuButtonClick(e) {
            let menu = document.getElementById('menu');

            if (this.isMenuActive) {
                this.addClass(menu, 'fadeOutUp');
                this.isMenuActive = false;
            }
            else {
                this.addClass(menu, 'menu-active fadeInDown');
                this.isMenuActive = true;
            }
            e.preventDefault();
        },

        smoothScroll(id){
            document.querySelector(id).scrollIntoView({
                behavior: 'smooth'
            });
        },
        resolution_pantallas(){
            // console.log(screen.width);
            return screen.width;
        },
        toggleMenu(event) {
				this.$refs.menu.toggle(event);
			},

        addClass(element, classNames) {
            let classNamesArr = classNames.split(' ');
            for (var i = 0; i < classNamesArr.length; i++) {
                let className = classNamesArr[i];
                if (element.classList)
                    element.classList.add(className);
                else
                    element.className += ' ' + className;
            }
        },

        removeClass(element, classNames) {
            let classNamesArr = classNames.split(' ');
            for (var i = 0; i < classNamesArr.length; i++) {
                let className = classNamesArr[i];
                if (element.classList)
                    element.classList.remove(className);
                else
                    element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }
    },
    components: {
            "Header": Header,
            "Footer": Footer,
            "Pasarela": Pasarela
    }
}
</script>

<style lang="scss" scoped>


.container-banner-seway{
    // margin-right: 0rem;
    background: url(https://erp.seway.com.co/layout/images/fondo-banner-generadores.png);
    // background: url(http://localhost:8080/layout/images/fondo-banner-generadores.png);
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 7rem !important;
}
.title-banner{
    color: #0A354E;
    font-size: 3.5rem;
    line-height: normal;
}
.description-banner{
    color: #0A354E;
    font-size: 1.25rem;
    line-height: normal;
}
.hr-vertical-banner{
    border-left: 4px solid #EB7D10;
}

.button-banner-1{
    font-weight: 500;
    background: #EB7D10;
    color: white;
    font-size: 1.5rem;
    border-radius: 25px;
}
.title-ventajas{
    color: #0A354E;
    font-size: 2.25rem;
    line-height: normal;
}
.listado-ventajas{
    color: #0A354E;
    font-size: 1.2rem;
    line-height: 0.5;
}
.button-banner-1:hover{
    // font-weight: 400;
    background: #0A354E;
    color: white;
    // font-size: 1.25rem;
}
.button-banner-2{
    font-weight: 500;
    background: #0A354E;
    color: white;
    font-size: 1.5rem;
    border-radius: 25px;
}
.fondo-ventajas{
    // background: url(http://localhost:8080/layout/images/ventajas-generador.png);
    background: url(https://erp.seway.com.co/layout/images/ventajas-generador.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.button-banner-2:hover{
    // font-weight: 400;
    background: #EB7D10;
    color: white;
    // font-size: 1.5rem;
}
#mano{
        width: 19rem;
    }
//moviles
@media only screen and (max-width: 800px) {
    .title-banner{
        color: #0A354E;
        font-size: 2rem;
        line-height: normal;
        
    }
    .hr-vertical-banner{
        border-left: 4px solid #EB7D10;
        margin-left: 20%;
    }
    #mano{
        width: 9rem;
    }
    .button-banner-1{
        font-weight: 200;
        background: #EB7D10;
        color: white;
        font-size: 1rem;
        margin-left: 17px;
    }
    .button-banner-1:hover{
        font-weight: 200;
        background: #0A354E;
        color: white;
        font-size: 1rem;
        margin-left: 17px;
    }
    .button-banner-2{
        font-weight: 200;
        background: #0A354E;
        color: white;
        font-size: 1rem;
        margin-left: 17px;
    }
    .button-banner-2:hover{
        font-weight: 200;
        background: #EB7D10;
        color: white;
        font-size: 1rem;
        margin-left: 17px;
    }
    
    .container-banner-seway{
        margin-right: 0rem;
        margin-top: 0rem !important;
    }
    
}
</style>
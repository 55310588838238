<template>
    <Login v-if="$route.path === '/login'" />
    <PruebaLogin v-if="$route.path === '/prueba-login'" />
    <Especiales v-if="$route.path === '/info-especiales'" />
    <InfoGeneradores v-if="$route.path === '/info-generadores'" />
    <InfoProveedor v-if="$route.path === '/info-proveedores'" />
    <InfoTerceros v-if="$route.path === '/info-terceros'" />
    <Pasarela v-if="$route.path === '/pasarela'" />
    <ServicioEspecial v-if="$route.path === '/registrar-servicios-especiales'" />
    <Generadores v-if="$route.path === '/registrar-generadores'" />
    <EmpresasTransporte v-if="$route.path === '/registrar-empresas-transporte'" />
    <Error v-else-if="$route.path === '/error'" />
    <Access v-else-if="$route.path === '/access'" />
    <NotFound v-else-if="$route.name === 'not-found'" />
    <Landing v-else-if="$route.path === '/landing'" />
    <EmailVerified v-else-if="$route.path === '/emailverify'" />
    <ContactUs v-else-if="$route.path === '/contactus'" />
    <Respuesta v-else-if="$route.path === '/respuesta-epayco'" />
    <ResetPaswword v-else-if="$route.path === '/resetpassword'" />
    <Home v-else-if="$route.path === '/home'" />
    <Spinner v-else-if="$route.path === '/'" />
    <PendienteActivacion v-else-if="$route.path === '/pendiente-activacion'" />
    <ConsultarDocumento v-else-if="$route.path === '/consulta-documento-cliente'" />
    
    <!-- <App :topbarTheme="topbarTheme" :layoutMode="layoutMode" :menuTheme="menuTheme" @menu-theme="onMenuTheme" @topbar-theme="onTopbarThemeChange" @layout-mode-change="onLayoutChange" v-else /> -->
    <!-- <template v-else> -->
        <App :topbarTheme="topbarTheme"
            :layoutMode="layoutMode"
            :menuTheme="menuTheme"
            @menu-theme="onMenuTheme"
            @topbar-theme="onTopbarThemeChange"
            @layout-mode-change="onLayoutChange"
            v-else-if="$route.name != 'not-found' &&
            $route.path!='/' && $route.path!='/login' &&
            $route.path!='/registrar-servicios-especiales' &&
            $route.path!='/registrar-generadores' &&
            $route.path!='/registrar-empresas-transporte' &&
            $route.path!='/pasarela' &&
            $route.path!='/prueba-login' &&
            $route.path!='/info-especiales' &&
            $route.path!='/info-generadores' &&
            $route.path!='/info-proveedores' &&
            $route.path!='/info-terceros' &&
            $route.path!='/respuesta-epayco' &&
            $route.path!='/consulta-documento-cliente'" />
    <!-- </template> -->
</template>

<script>
    import App from './App.vue';
    import Pasarela from './pages/PasarelaRegistro';

    import Especiales from './views/info_roles/Especiales.vue';
    import InfoGeneradores from './views/info_roles/Generador.vue';
    import InfoProveedor from './views/info_roles/Proveedor.vue';
    import InfoTerceros from './views/info_roles/Terceros.vue';
    // import Especiales from './views/info_roles/Especiales.vue';
    import ServicioEspecial from './registros/RegistroEspeciales';
    import Respuesta from './views/generador/respuestas_epayco/Respuesta.vue';
    import Generadores from './registros/RegistroGeneradores';
    import EmpresasTransporte from './registros/RegistroEmpresas';
    import Error from './pages/Error';
    import Access from './pages/Access';
    import Login from './pages/Login';
    import PruebaLogin from './pages/prueba_login';
    import NotFound from './pages/NotFound';
    import Landing from './pages/Landing';
    import EmailVerified from './pages/EmailVerified';
    import ConsultarDocumento from './components/ConsultaDocumentoCliente';
    import ContactUs from './pages/ContactUs';
    import Home from './pages/Home';
    import ResetPaswword from './pages/ResetPaswword';
    import Spinner from './pages/Spinner';
    import PendienteActivacion from './pages/PendienteActivacion';
    import EventBus from './event-bus';
    
	export default {
        data() {
            return {
                theme: 'blue',
                layoutMode: 'light',
                topbarTheme: 'blue',
                menuTheme: 'bluegrey',
            }
        },
        created() {
            
                if(this.$store.getters['infoNavigation/IdRol'] == 1){
                    this.topbarTheme = 'blue';
                    
                }else if (this.$store.getters['infoNavigation/IdRol'] == 2){
                    // this.topbarTheme = 'teal';
                    this.topbarTheme = 'white';
                }else if (this.$store.getters['infoNavigation/IdRol'] == 3){
                    this.topbarTheme = 'brown';
                }else if (this.$store.getters['infoNavigation/IdRol'] == 5){
                    this.topbarTheme = 'white';
                    this.menuTheme = 'indigo';
                }
        },
        mounted() {
            EventBus.on('show-container', (data) => {
                if(data == 1){
                    this.topbarTheme = 'blue';
                }else if (data == 2){
                    // this.topbarTheme = 'teal';
                    this.topbarTheme = 'white';
                }else if (data == 3){
                    this.topbarTheme = 'brown';
                }else if (data == 5){
                    this.topbarTheme = 'white';
                }
            });
        },
        methods: {
            onLayoutChange(layout) {
                this.layoutMode = layout;
                this.$appState.layoutMode = layout;
                this.menuTheme = layout;

                const logo = document.getElementById('logo');
                logo.src = 'layout/images/systram-blanco.png';
                
                if (layout === 'dark') {
                    this.topbarTheme = 'dark';
                }
                else {
                    this.topbarTheme = 'blue';
                }
            },
            onTopbarThemeChange(theme) {
                this.topbarTheme = theme.name;

                const themeName = theme.name;
                const logo = document.getElementById('logo');

                if (themeName == 'white' || themeName == 'yellow' || themeName == 'amber'  || themeName == 'orange' || themeName == 'lime') {
                    logo.src = 'layout/images/systram-blanco.png';
                }
                else {
                    logo.src = 'layout/images/systram-blanco.png';
                }
            },
            onMenuTheme(menuTheme) {
                this.menuTheme = menuTheme.name;
            }
        },
        components: {
            "App": App,
            "Pasarela": Pasarela,
            "Especiales": Especiales,
            "InfoGeneradores": InfoGeneradores,
            "InfoTerceros": InfoTerceros,
            "InfoProveedor": InfoProveedor,
            "ServicioEspecial": ServicioEspecial,
            "Generadores": Generadores,
            "EmpresasTransporte": EmpresasTransporte,
            "Error": Error,
            "Respuesta": Respuesta,
            "Access": Access,
            "Login": Login,
            "ConsultarDocumento": ConsultarDocumento,
            "PruebaLogin": PruebaLogin,
            "NotFound": NotFound,
            "Landing": Landing,
            "ContactUs": ContactUs,
            "EmailVerified": EmailVerified,
            "Home": Home,
            "ResetPaswword": ResetPaswword,
            "Spinner" : Spinner,
            "PendienteActivacion" : PendienteActivacion
        }
	}
</script>

<style scoped>
</style>

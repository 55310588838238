<template>
<!-- <div :class="'landing-container fondo-'+id_rol"> -->
<!-- <div class="landing-container"> -->
<div>
    <!-- <Header /> -->

    <!-- <div id="banner-seway" class="flex flex-column"> -->
        <!-- <ul> -->
            <!-- <div class="col-12 lg:col-3 ml-5" style="margin-top: 10rem !important;margin-bottom: 9rem !important;"> -->
            <div class="col-12 lg:col-12">
        <div class="card height-100">
            <template v-if="loader==true">
                <ProgressBar mode="indeterminate" style="height: .5em" />
                </template>
            <div class="card-header">
                <span class="description-banner">Seleccione un Rol</span>
                
              
            </div>
                    <div class="espacio-roles">
                        <template v-for="(rol,index) in roles" :key="index">
                                <template v-if="rol.id==ruta_activa">
                                    <img id="logo" :src="'layout/images/pages/logos_roles/'+rol.id+'.png'" alt="ultima-layout" @click="rol_selected(rol)" style="height: 3.25rem;" class="m-1 mx-3 icono-rol-activo p-1 cursor-pointer" :title="rol.name">
                                </template>
                                <template v-else>
                                    <img id="logo" :src="'layout/images/pages/logos_roles/'+rol.id+'.png'" alt="ultima-layout" @click="rol_selected(rol)" style="height: 3.25rem;" class="m-1 mx-3 icono-rol p-1 cursor-pointer" :title="rol.name">
                                </template>
                            
                        </template>
                    </div>
                     <template v-if="id_rol == 1">
                        <blockquote class="hr-vertical-banner pr-1"><p class="title-banner">Información<br> <strong>Transportes Especiales</strong></p></blockquote>
                        <div class="grid justify-content-center align-items-center mt-5">
                                <router-link class="layout-topbar-logo" to="/login">
                                <Button label="Soy empresa" class=" mb-3 button-banner-1 rounded-pill mr-3" to="/login"/>
                                </router-link>
                                <router-link class="layout-topbar-logo" to="/registrar-servicios-especiales">
                                <Button label="Registrarme" class=" mb-3 button-banner-2 rounded-pill" to="/login"/>
                                </router-link>
                        </div>
                    </template>
                    <template v-if="id_rol == 2">
                            <p class="title-banner mt-3"><strong>Generadores de carga</strong></p>
                            <p class="description-banner mt-3 text-center">Es la persona (natural o jurídica) que tiene el derecho sobre la <br>
                                mercancía y quien requiere el servicio de transporte</p>
                            <div class="grid my-5 justify-content-center align-items-center">
                                    <router-link class="layout-topbar-logo" to="/login">
                                    <Button label="Soy generador" class=" mb-3 button-banner-1 rounded-pill mr-3" to="/login"/>
                                    </router-link>
                                    <router-link class="layout-topbar-logo" to="/registrar-generadores">
                                    <Button label="Registrarme" class=" mb-3 button-banner-2 rounded-pill" to="/login"/>
                                    </router-link>
                            </div>
                    </template>
                    <template v-if="id_rol == 3">
                        <blockquote class="hr-vertical-banner pr-1"><p class="title-banner">Información<br> <strong>Proveedores</strong></p></blockquote>
                        <div class="grid my-5 justify-content-center align-items-center">
                                <router-link class="layout-topbar-logo" to="/login">
                                <Button label="Soy proveedor" class=" mb-3 button-banner-1 rounded-pill mr-3" to="/login"/>
                                </router-link>
                                <router-link class="layout-topbar-logo" to="/registrar-generadores">
                                <Button label="Registrarme" class=" mb-3 button-banner-2 rounded-pill" to="/login"/>
                                </router-link>
                        </div>
                    </template>   
                    <template v-if="id_rol == 4">
                        <blockquote class="hr-vertical-banner pr-1"><p class="title-banner">Información<br> <strong>Terceros</strong></p></blockquote>
                        <div class="grid my-5 justify-content-center align-items-center">
                                <router-link class="layout-topbar-logo" to="/login">
                                <Button label="Soy proveedor" class=" mb-3 button-banner-1 rounded-pill mr-3" to="/login"/>
                                </router-link>
                                <router-link class="layout-topbar-logo" to="/registrar-generadores">
                                <Button label="Registrarme" class=" mb-3 button-banner-2 rounded-pill" to="/login"/>
                                </router-link>
                        </div>
                    </template> 
                    <template v-if="id_rol == 5">
                        <blockquote class="hr-vertical-banner pr-1"><p class="title-banner mt-3">Información<br> <strong>Empresa de Transporte</strong></p></blockquote>
                        <div class="grid justify-content-center align-items-center mt-5">
                                <router-link class="layout-topbar-logo" to="/login">
                                <Button label="Soy empresa" class=" mb-3 button-banner-1 rounded-pill mr-3" to="/login"/>
                                </router-link>
                                <router-link class="layout-topbar-logo" to="/registrar-empresas-transporte">
                                <Button label="Registrarme" class=" mb-3 button-banner-2 rounded-pill" to="/login"/>
                                </router-link>
                        </div>
                    </template> 
                     
                <!-- </li>
            </ul> -->
        </div>
    </div>
    </div>
	
</template>

<script>
import axios from 'axios'
import Header from '../landing/Header';
import Footer from '../landing/Footer';
	export default {
		data() {
			return {
				loader: false,
                id_rol: 1,
                ruta_activa: 0,
                roles: []
			}
		},
        mounted(){
            this.consulta_roles();
            switch (this.$route.path) {
                case '/info-especiales':
                    this.ruta_activa = 1;
                    this.id_rol = 1;
                    break;
                case '/info-generadores':
                    this.ruta_activa = 2;
                    this.id_rol = 2;
                    break;
                case '/info-proveedores':
                    this.ruta_activa = 3;
                    this.id_rol = 3;
                    break;
                case '/info-terceros':
                    this.ruta_activa = 4;
                    this.id_rol = 4;
                    break;
                case '/info-transporte':
                    this.ruta_activa = 5;
                    this.id_rol = 5;
                    break;
                default:
                    break;
            }
        },
        components: {
            "Header": Header,
            "Footer": Footer,
        },
		methods: {
            consulta_roles(){
                this.roles = [];
                this.loader = true;
                axios.get('/roles').then(response => {
                    this.roles = response.data.data;
                    this.loader = false;
                    // console.log(this.roles);

                }).catch(e => {
                    console.log(e);
                    this.loader = false;
                });
            },
            rol_selected(rol){
                console.log(rol);
                this.id_rol = rol.id;
                switch (rol.id) {
                    case 1:
                        this.$router.push({ path: '/info-especiales' });
                        break;
                    case 2:
                        this.$router.push({ path: '/info-generadores' });
                        break;
                    case 3:
                        this.$router.push({ path: '/info-proveedores' });
                        break;
                    case 4:
                        this.$router.push({ path: '/info-terceros' });
                        break;
                    case 5:
                        this.$router.push({ path: '/info-transporte' });
                        break;
                    default:
                        break;
                }
            }
		}
	}
</script>

<style scoped>
.icono-rol{
    color: #0A354E;
    border-radius: 15px;
    
}
.icono-rol-activo{
    color: #EB7D10;
    border-radius: 15px;
    -webkit-box-shadow: 0px 0px 42px 4px rgba(235,125,16,1);
    -moz-box-shadow: 0px 0px 42px 4px rgba(235,125,16,1);
    box-shadow: 0px 0px 42px 4px rgba(235,125,16,1);
}
.icono-rol:hover{
    color: #EB7D10;
    transition: 0.5s;
    -webkit-box-shadow: 0px 0px 42px 4px rgba(235,125,16,1);
    -moz-box-shadow: 0px 0px 42px 4px rgba(235,125,16,1);
    box-shadow: 0px 0px 42px 4px rgba(235,125,16,1);
}
.fondo{
    background-color: rgb(148, 179, 179);
    background-size: cover;
    /* margin-top: 8rem !important; */
}
.fondo-1{
    background-color: rgb(148, 179, 179);
    background-size: cover;
}
.fondo-2{
    background-color: rgb(176, 191, 127);
    background-size: cover;
}
.fondo-3{
    background-color: rgb(191, 127, 127);
    background-size: cover;
}
.fondo-4{
    background-color: rgb(127, 134, 191);
    background-size: cover;
}
.fondo-5{
    background-color: rgb(188, 127, 191);
    background-size: cover;
}
.p-toolbar {
	background-color: #ffffff;
}
.title-banner{
    color: #0A354E;
    font-size: 3.5rem;
    line-height: normal;
}
.description-banner{
    color: #0A354E;
    font-size: 1.25rem;
    line-height: normal;
}
.button-banner-1{
    font-weight: 500;
    background: #EB7D10;
    color: white;
    font-size: 1.5rem;
    border-radius: 25px;
}
.button-banner-1:hover{
    /* // font-weight: 400; */
    background: #0A354E;
    color: white;
    /* // font-size: 1.25rem; */
}
.button-banner-2{
    font-weight: 500;
    background: #0A354E;
    color: white;
    font-size: 1.5rem;
    border-radius: 25px;
}
.button-banner-2:hover{
    /* // font-weight: 400; */
    background: #EB7D10;
    color: white;
    /* // font-size: 1.5rem; */
}
.espacio-roles{
    display: flex;
    justify-content: space-between;
}
.hr-vertical-banner{
    border-left: 4px solid #EB7D10;
}
@media only screen and (max-width: 800px) {
    .title-banner{
        color: #0A354E;
        font-size: 2rem;
        line-height: normal;
        
    }
    .button-banner-1{
        font-weight: 200;
        background: #EB7D10;
        color: white;
        font-size: 1rem;
        margin-left: 17px;
    }
    .button-banner-1:hover{
        font-weight: 200;
        background: #0A354E;
        color: white;
        font-size: 1rem;
        margin-left: 17px;
    }
    .button-banner-2{
        font-weight: 200;
        background: #0A354E;
        color: white;
        font-size: 1rem;
        margin-left: 17px;
    }
    .button-banner-2:hover{
        font-weight: 200;
        background: #EB7D10;
        color: white;
        font-size: 1rem;
        margin-left: 17px;
    }
}
</style>

<template>
	<div class="layout-topbar shadow-3">
		<div class="layout-topbar-left">
			<router-link class="layout-topbar-logo" to="/">
			<!-- <router-link class="layout-topbar-logo" :to="this.$store.getters['infoNavigation/rol']"> -->
				<!-- <img id="logo" src="layout/images/logo-light.svg" alt="ultima-layout" style="height: 2.25rem"> -->
				<img @click="volverselectcustomer" id="logo" src="layout/images/logo-header.png" alt="ultima-layout" style="height: 3.25rem">
			</router-link>
			<!-- <template v-if="$route.path != '/select-customer'">
			<a @click="volverselectcustomer">{{'Volver a pasarela'}}<i class="pi pi-sign-out m-3"></i></a>
			</template> -->
			<!-- <template :v-if="$route.path != this.$store.getters['infoNavigation/rol']+'/select-customer' && $route.path != this.$store.getters['infoNavigation/rol']+'/editar-perfil'"> -->
			<a class="layout-menu-button shadow-6 p-ripple" @click="onMenuButtonClick($event)" v-ripple>
				<i class="pi pi-chevron-right"></i>
			</a>
			<!-- </template> -->

			<a class="layout-topbar-mobile-button p-ripple" @click="onTopbarMobileButtonClick($event)" v-ripple>
				<i class="pi pi-ellipsis-v fs-large"></i>
			</a>
		</div>
		
		<div class="layout-topbar-right" :class="{'layout-topbar-mobile-active': mobileTopbarActive}">
			<div class="layout-topbar-actions-left">
				<!-- <MegaMenu :model="items" class="layout-megamenu"></MegaMenu> -->
			</div>

			<div class="layout-topbar-actions-right">
				<ul class="layout-topbar-items">

						<li class="layout-topbar-item">
							<a class="layout-topbar-action flex flex-row justify-content-center align-items-center px-3 p-ripple" @click="onTopbarItemClick($event, 'profile')" v-ripple>
							<!-- <img src="demo/images/avatar/amyelsner.png" alt="avatar" style="width: 32px; height: 32px;margin-right: 5px;"> -->
							{{nombre}}
							<img :src="img_profile" alt="avatar" style="width: 35px; height: 35px;margin-left: 5px;border-radius: 25px;">
							
						</a>

						<transition name="layout-topbar-action-panel">
							<ul class="layout-topbar-action-panel shadow-6" v-show="activeTopbarItem === 'profile'">
								<!-- <li class="layout-topbar-action-item">
									<a class="flex flex-row align-items-center p-ripple" v-ripple>
										<i class="pi pi-cog" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></i>
										<span>Configuración</span>
									</a>
								</li> -->
								<li class="layout-topbar-action-item">
									<a class="flex flex-row align-items-center p-ripple" v-ripple @click="cambiarcontraseña">
										<i class="pi pi-lock" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></i>
										<span>Cambiar Contraseña</span>
									</a>
								</li>
								<li class="layout-topbar-action-item">
									<a class="flex flex-row align-items-center p-ripple" :href="'/#'+this.$store.getters['infoNavigation/rol']+'/editar-perfil'">
										<i class="pi pi-user-edit" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></i>
										<span>Editar Perfil</span>
									</a>
								</li>
								<li class="layout-topbar-action-item">
									<a class="flex flex-row align-items-center" @click="cambiarrol">
										<i class="pi pi-users" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></i>
										<span>Cambiar de rol</span>
									</a>
									<!-- <template v-if="loader==true">
									<div class="col">
										<ProgressBar mode="indeterminate" style="height: .5em" />
									</div>
									</template> -->
								</li>
								<li class="layout-topbar-action-item" @click="logout">
									<a class="flex flex-row align-items-center p-ripple" v-ripple @click="logout">
										<i class="pi pi-power-off" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></i>
										<span>Cerrar Sesión</span>
									</a>
								</li>
							</ul>
						</transition>
					</li>
				</ul>
			</div>
		</div>
		
		<Dialog v-model:visible="modalResetPassword" :style="{width: '450px'}" header="Cambiar Contraseña" :modal="true" class="p-fluid">
			<!-- <img :src="'demo/images/product/' + product.image" :alt="product.image" v-if="product.image" width="150" class="mt-0 mx-auto mb-5 block shadow-2" /> -->
			<div class="field">
				<label for="clave_actual">Clave Actual</label>
				<InputText type="password" id="clave_actual" v-model.trim="frmReset.clave_actual" required="true" autofocus :class="{'p-invalid': submitted && !frmReset.clave_actual}" />
				
			</div>
			<div class="field">
				<label for="name">Clave Nueva</label>
				<!-- <InputText id="codigo" v-model.trim="frmReset.clave_nueva" required="true" autofocus :class="{'p-invalid': submitted && !frmReset.clave_nueva}" /> -->
				<Password v-model="frmReset.clave_nueva" toggleMask strongRegex="(?=.*?[8])">
					<template #footer="sp">
						{{sp.level}}
						<Divider />
						<p class="mt-2">Parámetros</p>
						<ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
							<li>Mínimo 8 dígitos</li>
						</ul>
					</template>
				</Password>
			</div>
			<div class="field">
				<label for="description">Confirmar Clave nueva</label><br>
				<!-- <InputText id="codigo" v-model.trim="frmReset.confirm_clave_nueva" required="true" autofocus :class="{'p-invalid': submitted && !frmReset.confirm_clave_nueva}" /> -->
				<Password v-model="frmReset.confirm_clave_nueva" toggleMask strongRegex="(?=.*?[8])">
					<template #footer="sp">
						{{sp.level}}
						<Divider />
						<p class="mt-2">Parámetros</p>
						<ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
							<li>Mínimo 8 dígitos</li>
							<li>Debe ser igual a la clave nueva</li>
						</ul>
					</template>
				</Password>
			</div>
			
			<template #footer>
				<template v-if="loader==true">
					<div class="flex justify-content-center">
						<i class="pi pi-spin pi-spinner justify-content-center" style="font-size: 2rem"></i>
					</div>
					
				</template>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="modalResetPassword = false"/>
				<!-- <template v-if="!frmCostos.id_costos"> -->
				<Button id="buttoncrear" label="Cambiar Clave" icon="pi pi-check" class="p-button-text" @click="cambiarcontra" />
				<!-- </template>
				<template v-else>
					<Button id="buttoneditar" label="Editar" icon="pi pi-check" class="p-button-text" @click="editarcentrocostos" />
				</template> -->
			</template>
		</Dialog>
		<Dialog header="Seleccione el rol" v-model:visible="modalselectrol" :breakpoints="{'960px': '85vw'}" :style="{width: '30vw'}" :modal="true" class="p-fluid" :closable="true">
			<!-- <p>Por favor ingrese su email</p> -->
			<div class="grid formgrid">
				<div class="col-12 mb-2 lg:col-12">
					<div class="field">
						<label for="inventoryStatus" class="mb-3">Seleccionar rol</label>
						<Dropdown v-model="selectrol" :options="roles" optionLabel="description" placeholder="Seleccionar un rol" />
						<small class="p-invalid p-error" v-if="!selectrol">Selección de rol es requerido.</small>
					</div>
				</div>
			</div>
			<template v-if="loader==true">
					<i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
				</template>
			<template #footer>
				<Button label="Ir al panel" @click="rolselected" icon="pi pi-home" class="p-button-raised p-button-primary"/>
			</template>
		</Dialog>
		<Toast />
	</div>
	
</template>

<script>
import axios from 'axios'
import { APIENDPOINTIMG } from './app.config'
import EventBus from './event-bus';

// axios.defaults.baseURL = APIENDPOINT;
	export default {
		emits: ['menubutton-click', 'topbar-menubutton-click', 'topbaritem-click', 'rightpanel-button-click', 'topbar-mobileactive', 'search-click', 'search-toggle'],
		data() {
			return {
				searchText: '',
				nombre: '',
				NameCustomer: '',
				modalResetPassword: false,
				loader: false,
				frmReset:{
					clave_actual: '',
					clave_nueva: '',
					confirm_clave_nueva: ''
				},
				submitted: false,
				img_profile: '',
				rol: this.$store.getters['infoNavigation/rol'],
				items: [
					{
						label: 'UI KIT',
						items: [
							[
								{
									label: 'UI KIT 1',
									items: [
										{ label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/formlayout' },
										{ label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input' },
										{ label: 'Float Label', icon: 'pi pi-fw pi-bookmark', to: '/floatlabel' },
										{ label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button' },
										{ label: 'File', icon: 'pi pi-fw pi-file', to: '/file' }
									]
								}
							],
							[
								{
									label: 'UI KIT 2',
									items: [
										{ label: 'Table', icon: 'pi pi-fw pi-table', to: '/table' },
										{ label: 'List', icon: 'pi pi-fw pi-list', to: '/list' },
										{ label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree' },
										{ label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel' },
										{ label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart' }
									]
								}
							],
							[
								{
									label: 'UI KIT 3',
									items: [
										{ label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay' },
										{ label: 'Media', icon: 'pi pi-fw pi-image', to: '/media' },
										{ label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menus' },
										{ label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages' },
										{ label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc' }
									]
								}
							]
						]
					},
					{
						label: 'UTILITIES',
						items: [
							[
								{
									label: 'UTILITIES 1',
									items: [
										{ label: 'Display', icon: 'pi pi-fw pi-desktop', to: '/display' },
										{ label: 'Elevation', icon: 'pi pi-fw pi-external-link', to: '/elevation' }
									]
								},
								{
									label: 'UTILITIES 2',
									items: [
										{ label: 'FlexBox', icon: 'pi pi-fw pi-directions', to: '/flexbox' }
									]
								}
							],
							[
								{
									label: 'UTILITIES 3',
									items: [
										{ label: 'Icons', icon: 'pi pi-fw pi-search', to: '/icons' }
									]
								},
								{
									label: 'UTILITIES 4',
									items: [
										{ label: 'Text', icon: 'pi pi-fw pi-pencil', to: '/text' },
										{ label: 'Widgets', icon: 'pi pi-fw pi-star', to: '/widgets' }
									]
								}
							],
							[
								{
									label: 'UTILITIES 5',
									items: [
										{ label: 'Grid System', icon: 'pi pi-fw pi-th-large', to: '/grid' },
										{ label: 'Spacing', icon: 'pi pi-fw pi-arrow-right', to: '/spacing' },
										{ label: 'Typography', icon: 'pi pi-fw pi-align-center', to: '/typography' }
									]
								}
							],
						]
					}
				],
				modalselectrol: false,
				selectrol: null,
				roles: [],
			}
		},
		props: {
			horizontal: {
				type: Boolean,
				default: false
			},
			topbarMenuActive: {
				type: Boolean,
				default: false
			},
			activeTopbarItem: String,
			mobileTopbarActive: Boolean,
			searchActive: Boolean
		},
		created(){
			this.infouser();
			EventBus.on('sistemalogout', () => this.logout());	
			EventBus.on('actualizarimgUser', () => this.infouser());	
		},
		methods: {
			volverselectcustomer(){
				// this.$router.push({ path: this.$store.getters['infoNavigation/rol']+'/select-customer' });
				// localStorage.removeItem('NameCustomer');
				document.cookie="NameCustomer=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
				document.cookie="UrlImgCustomer=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
				this.NameCustomer = '';
				EventBus.emit('hidden-img-customer');
			},
			cambiarrol(){
				var _that = this;
				const Email = 'Email';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				})
				var url = '/consulta_roles';
				this.loader = true;
				EventBus.emit('on-loader');
                window.api.call('post', url, {user: cookie[Email]}).then( function(response) {
                    if(response.status == 200){
                    //    console.log(response.data.data);
						_that.roles = response.data.data;
						if(_that.roles.length > 1){
							_that.modalselectrol = true;
						}else{
							_that.$toast.add({severity:'error', summary: 'No tiene más roles disponibles', detail: '', life: 4000});
						}
						
						// _that.hideDialog();
						// _that.$toast.add({severity:'success', summary: 'Creación exitosa', detail: response.data.message, life: 4000});
						_that.loader = false;
						EventBus.emit('off-loader');
                    }
                }).catch(function (err) {
					if (err.response) {
						_that.loader = false;
						EventBus.emit('off-loader');
						// console.log(err.response);
						// _that.$toast.add({severity:'error', summary: 'Error', detail:err.response.data.message, life: 4000});
						// buttoncrear.removeAttribute('disabled');
					}
                });
				// document.cookie = "IdRol="+1;
				// window.location.reload();
			},
			rolselected(){
				var _that = this;
				if(!this.selectrol){
                    this.$toast.add({severity:'error', summary: 'Error en envio', detail:'Debes seleccionar un rol', life: 5000});
                }else{

					// console.log(this.selectrol);
					document.cookie = "IdRol="+_that.selectrol.id_role;
					_that.$store.dispatch('infoNavigation/getIdRol',_that.selectrol.id_role);
					if(_that.selectrol.id_role == 1){
						_that.$store.dispatch('infoNavigation/getRol','/especiales');
						_that.$router.push({path: '/especiales'});
						window.location.reload();
						EventBus.emit('show-container',_that.selectrol.id_role);
					}else if (_that.selectrol.id_role == 2){
						document.cookie = "Rol="+'/generador';
						_that.$router.push({path: '/generador'});
						window.location.reload();
						EventBus.emit('show-container',_that.selectrol.id_role);
					}else if (_that.selectrol.id_role == 3){
						document.cookie = "Rol="+'/proveedor';
						_that.$router.push({path: '/proveedor'});
						window.location.reload();
						EventBus.emit('show-container',_that.selectrol.id_role);
					}else if (_that.selectrol.id_role == 4){
						document.cookie = "Rol="+'/terceros';
						_that.$router.push({path: '/terceros'});
						window.location.reload();
						EventBus.emit('show-container',_that.selectrol.id_role);
					}else if (_that.selectrol.id_role == 5){
						document.cookie = "Rol="+'/empresa-transporte';
						_that.$router.push({path: '/empresa-transporte'});
						window.location.reload();
						EventBus.emit('show-container',_that.selectrol.id_role);
					}else{
						_that.$router.push({path: '/'});
					}
					_that.modalselectrol = false;
					
					EventBus.emit('show-container',_that.selectrol.id_role);

				}
			},
			infouser(){
				const cookieName = 'Nombre';
				const cookieCustomer = 'NameCustomer';
				const ImgUser = 'ImgUser';
				const id_rol = 'IdRol';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				})
				// console.log(cookie[cookieName]);
				console.log(cookie[ImgUser]);
				// console.log(cookie[id_rol]);
				if(cookie[id_rol] == 1){
					this.nombre = cookie[cookieName]+' - Rol: Especiales';
				}else if(cookie[id_rol] == 2){
					this.nombre = cookie[cookieName]+' - Rol: Generador';
				}else if(cookie[id_rol] == 5){
					this.nombre = cookie[cookieName]+' - Rol: Empresa de Transporte';
				}else{
					this.nombre = cookie[cookieName];
				}
				
				this.NameCustomer = cookie[cookieCustomer];
				if(cookie[ImgUser]=='null'){
					this.img_profile = APIENDPOINTIMG+'img_profiles/profile.jpg';
				}else{
					this.img_profile = APIENDPOINTIMG+'img_profiles/'+cookie[ImgUser];
				}
				
			},
			onSearchContainerClick(event) {
				this.$emit("search-click", event);
			},
			changeSearchActive(event) {
				this.$emit('search-toggle', event);
			},
			onMenuButtonClick(event) {
				this.$emit('menubutton-click', event);
			},
			onTopbarMenuButtonClick(event) {
				this.$emit('topbar-menubutton-click', event);
			},
			onTopbarItemClick(event, item) {
				if(item === 'search') {
					this.$emit('search-toggle', event);
				}

				this.$emit('topbaritem-click', {originalEvent: event, item: item});
			},
			onTopbarMobileButtonClick(event) {
				this.$emit('topbar-mobileactive', event);
			},
			onRightPanelButtonClick(event) {
				this.$emit('rightpanel-button-click', event);
			},
			onSearchKeydown(event) { 
				if (event.keyCode == 13) {
					this.$emit('search-toggle', event);
				}
			},
			onEnter() {
				if (this.$refs.searchInput) {
					this.$refs.searchInput.$el.focus();
				}
			},
			cambiarcontraseña(){
				this.frmReset.clave_actual = '';
				this.frmReset.clave_nueva = '';
				this.frmReset.confirm_clave_nueva = '';
				this.modalResetPassword = true;
			},
			cambiarcontra(){
				this.submitted = false;
				if(!this.frmReset.clave_actual || !this.frmReset.clave_nueva || !this.frmReset.confirm_clave_nueva){
                    this.$toast.add({severity:'error', summary: 'Error', detail:'Debe llenar todos los campos!', life: 3000});
					this.submitted = true;
                }else{
					if(this.frmReset.confirm_clave_nueva==this.frmReset.clave_nueva){
						var buttoncrear = document.getElementById('buttoncrear');
						buttoncrear.setAttribute("disabled", "");
						const EmailUser = 'Email';
						let cookie = {};
						document.cookie.split(';').forEach(function(el) {
						let [key,value] = el.split('=');
						cookie[key.trim()] = value;
						})
						var _that = this;
						this.loader = true;
						axios.post('/cambiar_clave', {frmReset: this.frmReset,email: cookie[EmailUser]}).then(response => {
							// console.log(response.data.message);
							_that.modalResetPassword = false;
							// _that.hideDialog();
							_that.$toast.add({severity:'success', summary: 'Creación exitosa', detail: response.data.message, life: 4000});
							_that.loader = false;
						}).catch(function (error) {
							if (error.response) {
								_that.loader = false;
								// console.log(error.response);
								_that.$toast.add({severity:'error', summary: 'Error', detail: error.response.data.message, life: 4000});
								buttoncrear.removeAttribute('disabled');
							}
						});
					}else{
						this.$toast.add({severity:'error', summary: 'Error', detail:'La clave nueva no coincide con la confirmación!', life: 3000});
					}
					
				}
			},
			logout(){
			var _that = this;
			var token = this.$store.getters['infoNavigation/access'];
			EventBus.emit('on-loader');
			axios.post('/logout',token).then((response) => {
					document.cookie="ImgUser=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"; 
					document.cookie="Email=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"; 
					document.cookie="Nombre=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
					document.cookie="NameCustomer=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
					document.cookie="IdCustomer=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
					document.cookie="UrlImgCustomer=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
					document.cookie="IdUsuarioRol=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
					document.cookie="UserAdminCustomer=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
					document.cookie="Rol=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
					document.cookie="IdRol=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
					_that.$store.dispatch('infoNavigation/destroyAccess',null);
					_that.$store.dispatch('infoNavigation/destroyIdRol',null);
					_that.$router.push({ path: '/login' });
					// console.log(response.data.message);
				});
			}
		},
		computed: {
			topbarItemsClass() {
				return ['topbar-items animated fadeInDown', {
					'topbar-items-visible': this.topbarMenuActive
				}];
			},
			isRTL() {
				return this.$appState.RTL;
			}
		}
	}
</script>

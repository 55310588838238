<template>
	<div class="pages-body login-page flex flex-column">
    <!-- <div class="topbar p-3 flex justify-content-between flex-row align-items-center">
        <router-link class="topbar-left ml-3 flex" to="/">
            <div class="logo">
                <img src="assets/layout/images/systram-azul.png" alt=""/>
            </div>
        </router-link> -->
        <!-- <router-link class="topbar-right mr-3 flex" to="/">
            <Button type="button" label="DASHBOARD" class="p-button-text p-button-plain topbar-button"></Button>
        </router-link> -->
    <!-- </div> -->

    <div class="align-self-center mt-auto mb-auto">
        <div class="pages-panel card flex flex-column" style="margin-top: 8rem;">
            <!-- LOGIN! -->
            <div class="pages-header px-3 py-1">
				<template v-if="switchValue == true">
                <h2>CONSULTAR DOCUMENTO CLIENTE</h2>
				</template>
				<template v-else>
                <h2>CONSULTAR NÚMERO DE MANIFIESTO</h2>
				</template>
            </div>
            <Toast />
            <h4>Movimientos de transporte</h4>
        
            <div class="pages-detail px-6">Acceda a los movimientos de transporte de manera agil y facil</div>
			<!-- <div class="pages-detail my-4">Núm Manifiesto <InputSwitch v-model="switchValue" /> Documento Cliente</div> -->
			<label class="mb-3 mt-3">Tipo Consulta</label>
						<div class="formgrid grid  align-items-center justify-content-center">
							<!-- <div class="field-radiobutton col-1">
								</div> -->
							<div class="field-radiobutton col-4 lg:mx-5">
								<RadioButton :value="false" v-model="switchValue" />
								<label for="category1">Núm Manifiesto</label>
							</div>
							<div class="field-radiobutton col-4 lg:mx-5">
								<RadioButton :value="true" v-model="switchValue" />
								<label for="category1">Documento Cliente</label>
							</div>
						</div>
            <div class="input-panel flex flex-column px-3">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-server"></i>
                    </span>
                    <span class="p-float-label">
						<template v-if="switchValue==false">
                        	<InputText type="text" id="documento_cliente" placeholder="Ingresa número de manifiesto" @keyup.enter="search_customer_document" v-model="documento_cliente"/>
						</template>
						<template v-else>
							<InputText type="text" id="documento_cliente" placeholder="Ingresa documento cliente" @keyup.enter="search_customer_document" v-model="documento_cliente"/>
						</template>
                    </span>
                </div>
            </div>
            <Button class="login-button mb-3 px-3 mt-3" :loading="loader" label="CONSULTAR" @click.prevent="search_customer_document"></Button>
       <Toast />
        </div>
        <Dialog v-model:visible="modalinfo" :style="{width: '850px'}" :modal="true" class="p-fluid">
			<template v-slot:header>
					<div class="formgrid grid  text-center flex">
						<div class="col-4 p-1">
							<p>Manifiesto:<br>
							#<strong>{{customers.manifiesto_numero}}</strong></p>
						</div>
						<div class="col-4 p-1">
							<p>Placa:<br>
							<strong>{{customers.vehiculo_placa}}</strong></p>
						</div>
						<div class="col-4 p-1">
							<p>Sucursal:<br>
							<strong>{{customers.remesa_sucursal}}</strong></p>
						</div>
					</div>
				</template>
				<div class="field formgrid grid">
					<div class="field col-12">
						<TabView>
							<TabPanel>
								<template #header>
									<i class="pi pi-info-circle"></i>
									<span class="ml-2">Info Movimiento</span>
								
								</template>
								<div class="field formgrid grid">
						
								<div class="field col-4 shadow-1 rounded-normal p-3">
									<h5>Producto</h5>
									<p>{{customers.producto_descripcion}}</p>
									<p>{{customers.empaque_descripcion}}</p>
								</div>
								<div class="field col-4 shadow-1 rounded-normal p-3">
									<h5>Valor</h5>
									<p>{{'$'+new Intl.NumberFormat().format(customers.valor_declarado)}}</p>
									<h5>Unidades</h5>
									<p><strong>Total: </strong>{{customers.unidades_final}}</p>
								</div>

								
								<div class="field col-4 shadow-1 rounded-normal p-3">
									<h5>Peso</h5>
									<!-- <p><strong>Inicial: </strong>{{customers.peso_inicial}}kg</p> -->
									<p><strong>Total: </strong>{{customers.peso_final}}kg</p>
								</div>
								<!-- <div class="field col-4 shadow-1 rounded-normal p-3">
									<h5>Unidades</h5>
									<p><strong>Total: </strong>{{customers.unidades_final}}</p>
								</div> -->
								<div class="field col-6 shadow-1 rounded-normal p-3">
									<h5>Remitente</h5>
									<p>NIT: #{{customers.remitente_documento}}</p>
									<p>{{customers.remitente_nombre}}</p>
									<p>{{customers.remitente_direccion}}<br>{{customers.remitente_zona}}</p>
									<p>{{customers.remitente_telefono}}</p>
								</div>
								<div class="field col-6 shadow-1 rounded-normal p-3">
									<h5>Destinatario</h5>
									<p>NIT: #{{customers.destinatario_documento}}</p>
									<p>{{customers.destinatario_nombre}}</p>
									<p>{{customers.destinatario_direccion}}<br>{{customers.destinatario_zona}}</p>
									<p>{{customers.destinatario_telefono}}</p>
								</div>
							</div>
							</TabPanel>
							<TabPanel>
								<template #header>
									<i class="pi pi-list"></i>
									<span class="ml-2">Eventos</span>
								</template>
								
								<div class="field formgrid grid flex py-6">
									<div class=" col-12 align-items-center"  id="content-time-line-tracking">
										<Timeline :value="details_remesa" layout="horizontal" align="top" class="customized-timeline">
											<template #marker="">
												<span class="custom-marker">
													<i class="pi pi-check-circle pink-color" style="font-size: 3rem"></i>
												</span>
											</template>
											<template #content="">
											</template>
										</Timeline>
									</div>
									<div class="field col-12 text-center">
										<template v-if="code_seway==1">
											<img src="layout/images/widgets/feature-faq.svg" alt="feature-faq" class="mb-2 mt-3">
											<h4>No se encuentran eventos en seway</h4>
										</template>
										<template v-if="code_seway==0">
											<img src="layout/images/widgets/feature-faq.svg" alt="feature-faq" class="mb-2 mt-3">
											<h4>No se pudo buscar los eventos</h4>
										</template>
										<div style="margin-top: 1em">
											<transition-group name="dynamic-box" tag="div" class="grid">
												<div v-for="(col,index) in details_remesa" :key="index" class="col">
													<div class="box">
													<p class="step_mov"><strong>
															<template v-if="col.id_events==2">
																Llegada de Carga
															</template>
															<template v-if="col.id_events==3">
																Inicio de Carga
															</template>
															<template v-if="col.id_events==4">
																Fin de Carga
															</template>
															<template v-if="col.id_events==5">
																Carga aceptada
															</template>
															<template v-if="col.id_events==6">
																Llegada a sitio Descargue
															</template>
															<template v-if="col.id_events==7">
																Inicio Descargue
															</template>
															<template v-if="col.id_events==8">
																Fin Descargue
															</template>
															<template v-if="col.id_events==9">
																Descarga Aceptada
															</template>
															<template v-if="col.id_events==10">
																Entrega Pendiente
															</template>
															<template v-if="col.id_events==11">
																Entrega Exitosa
															</template>
															<template v-if="col.id_events==12">
																Entrega Rechazada
															</template>
															<template v-if="col.id_events==13">
																Planta de carga reservada
															</template>
															<template v-if="col.id_events==14">
																Inicio de carga en planta
															</template>
															<template v-if="col.id_events==15">
																Fin de carga en planta
															</template>
															<template v-if="col.id_events==16">
																Inicio de descarga en planta
															</template>
															<template v-if="col.id_events==17">
																Fin de descarga en planta
															</template>
															<template v-if="col.id_events==18">
																Descargada en planta no reservada
															</template>
														</strong></p>
														<p>{{col.created_at}}</p>
														<template v-if="col.latitude && col.longitude">
														<a :href="'https://www.google.com.co/maps?q='+col.latitude+','+col.longitude" target="_blank">Ver en Mapa</a>
														</template>
														<br>
														<template v-if="col.image_event.length > 0">
															<Button icon="pi pi-eye" @click="show_galeria_evnets = true" class="p-button-rounded p-button-primary mt-2"/>
																<Galleria :value="col.image_event" :responsiveOptions="galleriaResponsiveOptions" :numVisible="3" containerStyle="max-width: 50%"
																	:circular="true" :fullScreen="true" :showItemNavigators="true" v-model:visible="show_galeria_evnets">
																	<template #item="slotProps">
																		<img :src="slotProps.item.image_url" :alt="slotProps.item.id" style="width: 60%; display: block;" />
																	</template>
																</Galleria>
														</template>
														<!-- <template v-if="col.image_event.length == 0 && col.url_image">
															<div class="flex justify-content-center">
																<Image :src="col.url_image" alt="Image" width="150" preview />
															</div>
														</template> -->
													</div>
												</div>
											</transition-group>
										</div>
									</div>
								</div>
							</TabPanel>
							<TabPanel>
								<template #header>
									<i class="pi pi-bookmark-fill"></i>
									
									<span class="ml-2 ">Cumplidos Manuales</span>
								</template>
								<div class="field col-12 text-center">
									<template v-if="customers.cumplidos_code==1">
										
										<h4 class="my-5">No se encuentran cumplidos manuales en systram</h4>
									</template>
								<!-- <div style="margin-top: 1em"> -->
									<!-- <transition-group name="dynamic-box" tag="div" class="grid card  align-items-center">
										<div  v-for="(col,index) in customers.cumplidos" :key="index" class="col">
											
											<p><strong>Descripcion: </strong><br>
											{{col.descripcion}}<br></p>

											<p><strong>Referencia: </strong><br>
											{{col.referencia}}<br></p>
											
											<p><strong>Documento: </strong><br>
											<a :href="col.ruta_digital" target="_blank" rel="noopener noreferrer">{{col.nombre}}</a><br></p>
											
										</div>
									</transition-group> -->
									
								<!-- </div> -->
								</div>
								<div class="field col-12 text-center">
								<DataTable :value="customers.cumplidos" let-i="rowIndex" v-model="table_cumplidos" selectionMode="single" :paginator="true" :rows="5" responsiveLayout="scroll">
										<Column field="descripcion" header="descripcion" :sortable="false">
											<template #body="slotProps">
												{{slotProps.data.descripcion}}
											</template>
										</Column>
										<Column field="referencia" header="referencia" :sortable="false">
											<template #body="slotProps">
												{{slotProps.data.referencia}}
											</template>
										</Column>
										<Column field="ruta_digital" header="Documento" :sortable="false">
											<template #body="slotProps">
												<a :href="slotProps.data.ruta_digital" target="_blank" rel="noopener noreferrer">{{slotProps.data.nombre}}</a>
											</template>
										</Column>
										<Column field="referencia" header="Ver Documento" :sortable="false">
											<template #body="slotProps">
												<a :href="slotProps.data.ruta_digital" target="_blank" rel="noopener noreferrer"><i class="pi pi-cloud-download ml-1 mt-1" style="font-size: 2rem"></i></a>
											</template>
										</Column>
									</DataTable>
								</div>
							</TabPanel>
						</TabView>
					</div>
				</div>
				<template v-if="loader==true">
					<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
				</template>
				<template #footer>
					<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="modalinfo = false"/>
					<!-- <Button id="agggenerador" label="Agregar" icon="pi pi-check" class="p-button-text" @click="agregar_customer" /> -->
				</template>
		</Dialog>
		<!-- <Dialog v-model:visible="modalDetalle" :style="{width: '900px'}" :header="'Remesas'" :modal="true" class="p-fluid"> -->
		<Dialog v-model:visible="modalDetalle" :style="{width: '900px'}" :modal="true" class="p-fluid">
				<template v-slot:header>
					<div class="field formgrid grid  text-center flex">
						<div class="col-3 p-2">
							<h6>Remesas</h6>
						</div>
						<div class="col-3 p-2">
							<p>Manifiesto:<br>
							#<strong>{{customers[0].manifiesto_numero}}</strong></p>
						</div>
						<div class="col-3 p-2">
							<p>Placa:<br>
							<strong>{{customers[0].vehiculo_placa}}</strong></p>
						</div>
						<div class="col-3 p-2">
							<p>Sucursal:<br>
							<strong>{{customers[0].remesa_sucursal}}</strong></p>
						</div>
					</div>
				</template>
		<div class="field formgrid grid">
			<!-- <div class="field col-12">
				<Accordion>
					<template v-for="(detalle_mov,index) in customers" :key="index">
					<AccordionTab>
						<template v-slot:header>
							<div class="flex ">
								<i class="pi pi-map-marker fs-xlarge mr-3 pink-color my-5"></i>
									<div class="field formgrid grid p-fluid">
										<div class="field col-3">
											<p><strong>Origen: </strong><br>{{detalle_mov.remitente_nombre}}<br>
											{{detalle_mov.origen_descripcion}}</p>
										</div>
										<div class="field col-3">
											<p><strong>Destino: </strong><br>{{detalle_mov.destinatario_nombre}}<br>
											{{detalle_mov.destino_descripcion}}</p>
										</div>
										<div class="field col-2">
											<p><strong>Carga: </strong><br>{{detalle_mov.producto_descripcion}}<br>
											{{new Intl.NumberFormat().format(detalle_mov.peso_final)}}Kg</p>
										</div>
										<div class="field col-3">
											<p><strong>Generador: </strong><br>{{detalle_mov.generador_nombre}}</p>
										</div>
										<div class="field col-1">
											<Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2 mt-3 text-right" :loading="loading[index]" @click="show_remesa(detalle_mov,index)" />
										</div>
									</div>
								
							</div>
						</template>
						<div class="field formgrid grid p-fluid">
							
							<div class="field col-6 shadow-1 rounded-normal p-3">
								<h5>Remitente</h5>
								<p>NIT: #{{detalle_mov.remitente_documento}}</p>
								<p>{{detalle_mov.remitente_nombre}}</p>
								<p>{{detalle_mov.remitente_direccion}}<br>{{detalle_mov.remitente_zona}}</p>
								<p>{{detalle_mov.remitente_telefono}}</p>
							</div>
							<div class="field col-6 shadow-1 rounded-normal p-3">
								<h5>Destinatario</h5>
								<p>NIT: #{{detalle_mov.destinatario_documento}}</p>
								<p>{{detalle_mov.destinatario_nombre}}</p>
								<p>{{detalle_mov.destinatario_direccion}}<br>{{detalle_mov.destinatario_zona}}</p>
								<p>{{detalle_mov.destinatario_telefono}}</p>
							</div>

							<div class="field col-4 shadow-1 rounded-normal p-3">
								<h5>Origen</h5>
								<p>{{detalle_mov.origen_descripcion+' - '+detalle_mov.origen_detalle}}</p>
							</div>
							<div class="field col-4 shadow-1 rounded-normal p-3">
								<h5>Destino</h5>
								<p>{{detalle_mov.destino_descripcion+' - '+detalle_mov.destino_detalle}}</p>
							</div>
							<div class="field col-4 shadow-1 rounded-normal p-3">
								<h5>Valor</h5>
								<p>{{'$'+new Intl.NumberFormat().format(detalle_mov.valor_declarado)}}</p>
							</div>

							<div class="field col-4 shadow-1 rounded-normal p-3">
								<h5>Producto</h5>
								<p>{{detalle_mov.producto_descripcion}}</p>
								<p>{{detalle_mov.empaque_descripcion}}</p>
							</div>
							<div class="field col-4 shadow-1 rounded-normal p-3">
								<h5>Peso</h5>
								<p><strong>Inicial: </strong>{{detalle_mov.peso_inicial}}</p>
								<p><strong>Final: </strong>{{detalle_mov.peso_final}}</p>
							</div>
							<div class="field col-4 shadow-1 rounded-normal p-3">
								<h5>Unidades</h5>
								<p><strong>Inicial: </strong>{{detalle_mov.unidades_inicial}}</p>
								<p><strong>Final: </strong>{{detalle_mov.unidades_final}}</p>
							</div>
						</div>

					</AccordionTab>
					</template>
				</Accordion>


			</div> -->
			<div class="field col-12 text-center">
						<DataTable :value="customers" let-i="rowIndex" v-model="table_cumplidos" selectionMode="single" :paginator="true" :rows="5" responsiveLayout="scroll">
								<Column field="origen_descripcion" header="Origen" :sortable="false">
									<template #body="slotProps">
										{{slotProps.data.remitente_nombre}}<br>{{slotProps.data.origen_descripcion}}
									</template>
								</Column>
								<Column field="Destino" header="Destino" :sortable="false">
									<template #body="slotProps">
										{{slotProps.data.destinatario_nombre}}<br>{{slotProps.data.destino_descripcion}}
									</template>
								</Column>
								<Column field="producto_descripcion" header="Carga" :sortable="false">
									<template #body="slotProps">
										{{slotProps.data.producto_descripcion}}<br>{{new Intl.NumberFormat().format(slotProps.data.peso_final)}}Kg
									</template>
								</Column>
								<Column field="generador_nombre" header="Generador" :sortable="false">
									<template #body="slotProps">
										{{slotProps.data.generador_nombre}}
									</template>
								</Column>
								<Column field="referencia" header="Ver Detalle" :sortable="false">
									<template #body="slotProps">
										<Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2 mt-3 text-right" :loading="loading[slotProps.index]" @click="show_remesa(slotProps.data,slotProps.index)" />
									</template>
								</Column>
							</DataTable>
					</div>
		</div>
		<template #footer>
			<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="modalDetalle = false"/>
		</template>
		</Dialog>
		<Dialog v-model:visible="modaldetalleremesas" :style="{width: '1000px'}" :header="'Documentos movimiento #'" :modal="true" class="p-fluid">
			<div class="field formgrid grid flex py-6">
				<div class="field col-12 align-items-center" id="content-time-line-tracking">
					<Timeline :value="details_remesa" layout="horizontal" align="top" class="customized-timeline">
						<template #marker="">
							<span class="custom-marker">
								<i class="pi pi-check-circle pink-color" style="font-size: 3rem"></i>
							</span>
						</template>
						<template #content="">
						</template>
					</Timeline>
				</div>
				<div class="field col-12 text-center">
					<template v-if="code_seway==1">
						<h4>No se encuentran eventos en seway</h4>
					</template>
					<template v-if="code_seway==0">
						<h4>Eventos no disponibles</h4>
					</template>
				<div style="margin-top: 1em">
					<transition-group name="dynamic-box" tag="div" class="grid">
						<div v-for="(col,index) in details_remesa" :key="index" class="col">
							<div class="box">
							<!-- <p>{{col}}</p> -->
							<p class="step_mov"><strong>
									<template v-if="col.id_events==2">
										Llegada de Carga
									</template>
									<template v-if="col.id_events==3">
										Inicio de Carga
									</template>
									<template v-if="col.id_events==4">
										Fin de Carga
									</template>
									<template v-if="col.id_events==5">
										Carga aceptada
									</template>
									<template v-if="col.id_events==6">
										Llegada a Descargue
									</template>
									<template v-if="col.id_events==7">
										Inicio Descargue
									</template>
									<template v-if="col.id_events==8">
										Fin Descargue
									</template>
									<template v-if="col.id_events==9">
										Descarga Aceptada
									</template>
									<template v-if="col.id_events==10">
										Entrega Pendiente
									</template>
									<template v-if="col.id_events==11">
										Entrega Exitosa
									</template>
									<template v-if="col.id_events==12">
										Entrega Rechazada
									</template>
									<template v-if="col.id_events==13">
										Planta de carga reservada
									</template>
									<template v-if="col.id_events==14">
										Inicio de carga en planta
									</template>
									<template v-if="col.id_events==15">
										Fin de carga en planta
									</template>
									<template v-if="col.id_events==16">
										Inicio de descarga en planta
									</template>
									<template v-if="col.id_events==17">
										Fin de descarga en planta
									</template>
									<template v-if="col.id_events==18">
										Descargada en planta no reservada
									</template>
								</strong></p>
								<p>{{col.created_at}}</p>
								<template v-if="col.latitude && col.longitude">
								<a :href="'https://www.google.com.co/maps?q='+col.latitude+','+col.longitude" target="_blank">Ver en Mapa</a>
								</template>
								<br>
								<template v-if="col.image_event.length > 0">
									<Button icon="pi pi-eye" @click="show_galeria_evnets = true" class="p-button-rounded p-button-primary mt-2"/>
									<!-- <div class="flex justify-content-center pb-8 pt-1"> -->
										<Galleria :value="col.image_event" :responsiveOptions="galleriaResponsiveOptions" :numVisible="3" containerStyle="max-width: 50%"
											:circular="true" :fullScreen="true" :showItemNavigators="true" v-model:visible="show_galeria_evnets">
											<template #item="slotProps">
												<img :src="slotProps.item.image_url" :alt="slotProps.item.id" style="width: 60%; display: block;" />
											</template>
										</Galleria>
								</template>
								<template v-if="col.image_event.length == 0 && col.url_image">
									<div class="flex justify-content-center">
										<Image :src="col.url_image" alt="Image" width="150" preview />
									</div>
								</template>
								
							</div>
						</div>
					</transition-group>
				</div>
				</div>
			</div>
			<template #footer>
				<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="modaldetalleremesas = false"/>
			</template>
		</Dialog>
		<Dialog header="Seleccione la sucursal" v-model:visible="modalselectrol" :breakpoints="{'960px': '85vw'}" :style="{width: '30vw'}" :modal="true" class="p-fluid" :closable="false">
                        <!-- <p>Por favor ingrese su email</p> -->
                        <div class="grid formgrid">
                            <div class="col-12 mb-2 lg:col-12">
                                <div class="field">
									<!-- <label for="inventoryStatus" class="mb-3">Seleccionar Sucursal</label> -->
									<Dropdown v-model="selectrol" :options="list_sucursales" optionLabel="remesa_sucursal" placeholder="Seleccionar una sucursal" />
									<small class="p-invalid p-error" v-if="!selectrol">Selección de sucursal es requerida.</small>
								</div>
                            </div>
                        </div>
                        <template v-if="loader==true">
							<div class="grid formgrid">
								<div class="col-12 mb-2 lg:col-12">
								<ProgressBar mode="indeterminate" style="height: .5em" />
								</div>
							</div>
						</template>
                        <template #footer>
							<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="modalselectrol = false"/>
							<Button label="Listo" icon="pi pi-check" class="p-button-text" @click="sucursal_selected"/>
                            <!-- <Button label="Ir al panel" :loading="loader" @click="rolselected" icon="pi pi-home" class="p-button-raised p-button-primary"/> -->
                        </template>
        </Dialog>
    </div>
</div>

</template>

<script>
	export default {
		data() {
			return {
				frmUsuarios: {
                    name: '',
                    last_name: '',
                    email: '',
                    password: '',
                    password_confirm: '',
                    auth_notification: '',
                    terms_conditions: ''
                },
                documento_cliente: '',
				modalselectrol: false,
				selectrol: null,
				table_cumplidos: null,
				loading: [false, false, false],
                haytoken: '',
				show_galeria_evnets: false,
				switchValue: false,
                listUsuarios: [],
				customers: [],
				detalle_reme: [],
				list_sucursales: [],
				galleriaResponsiveOptions: [
                {
                    breakpoint: "724px",
                    numVisible: 5,
                },
                {
                    breakpoint: "660px",
                    numVisible: 4,
                },
                {
                    breakpoint: "168px",
                    numVisible: 3,
                },
                {
                    breakpoint: "160px",
                    numVisible: 1,
                },
            ],
                details_remesa: [],
                code_seway: '',
                code_cumplido: '',
                message: [],
                list_cumplidos: [],
                registro: false,
				modaldetalleremesas: false,
				modalDetalle: false,
                loader: false,
                modalinfo: false,
                count: 0,
                checked: '',
                display: false,
			}
		},
        methods: {
            
            async search_customer_document(){
                // const urlParams = this.$route;
                const urlParams = this.$route.query.customer;
                console.log(urlParams);
                if (!this.documento_cliente) {
                   this.$toast.add({severity:'error', summary: 'Error', detail:"Debes ingresar el codigo", life: 5000}); 
                   return
                }
                if (urlParams==null) {
                   this.$toast.add({severity:'error', summary: 'Error', detail:"Debes tener nit en la ruta", life: 5000}); 
                   return
                }
                this.loader = true;
                this.modalDetalle = false;
                var _that = this;
                this.code_seway = '';
                this.code_cumplido = '';
                this.details_remesa = [];
                this.list_cumplidos = [];
                var url = 'search_customer_document';
                window.api.call('post', url, {nit_customer: urlParams,documento_cliente: this.documento_cliente,tipo_campo: this.switchValue}).then( function(response) {
                    if(response.status == 200){
                        if (response.data.data!=null) {
							if (_that.switchValue==false) {
								
								_that.detalle_reme = response.data.data_total;
								_that.list_sucursales = response.data.sucursales;
								// _that.customers = response.data.data_total;
								if (response.data.data_total.length>1) {
									_that.modalselectrol = true;
								}else{
									// _that.modalDetalle = true;  
									_that.modalinfo = true;  
									_that.details_remesa = response.data.estados.data;
									_that.customers = response.data.data_total[0];
								}
								
							}else{
								_that.customers = response.data.data;
								_that.modalinfo = true;
								_that.details_remesa = response.data.estados.data;
								// _that.list_cumplidos = response.data.cumplidos_manuales.cumplidos;
							}
                            
                            // _that.customers = response.data.data;
                            _that.code_seway = response.data.estados.code;
                            // _that.code_cumplido = response.data.cumplidos_manuales.code;
                            
                             
                        }
                        // console.log(_that.customers);
                        _that.loader = false;
                    
                    }
                }).catch(function (err) {
                    console.log(err);
					_that.$toast.add({severity:'warn', summary: 'No se encontro', detail:err.data, life: 5000}); 
                    _that.loader = false;
                });
                // const myParam = urlParams.get('user');
                // console.log(myParam);
            },
			sucursal_selected(){
				this.customers = [];
				console.log(this.selectrol);
				
				this.detalle_reme.forEach(detalle => {
					if (detalle.remesa_sucursal == this.selectrol.remesa_sucursal) {
						this.customers.push(detalle);
						// this.customers = this.selectrol;
					}
				
				});
				
				console.log(this.customers);
				// this.modalinfo = true;
				this.modalDetalle = true;
			},
			show_remesa(data,index){
				this.details_remesa = [];
				this.code_seway = '';
				this.loading[index] = true;
				var _that = this;
				var url = 'show_remesas_cumplidos';
				window.api.call('post', url, {id_customer: this.customer_selected,id_detalle: data.detalle_id}).then( function(response) {
					if(response.status == 200){
						// _that.modaldetalleremesas = true;
						_that.modalinfo = true;
						_that.modalDetalle = false;
						_that.details_remesa = response.data.data;
						_that.customers = data;
						_that.code_seway = response.data.code;
						_that.loading[index] = false;
					}
				}).catch(function (err) {
					_that.loading[0] = false;
					_that.$toast.add({severity:'error', summary: 'Info no disponible', detail:err.data.data, life: 4000});
					_that.loading[index] = false;
				});
			}
        }
	}
</script>

<style scoped>
#content-time-line-tracking{
	padding: 0px 6rem;
}
.pages-body.login-page {
    /* background: url(../images/pages/tracking.png) !important; */
    /* background: url(../images/pages/login-bg.jpg); */
	background-image: url('../../public/layout/images/pages/tracking.png') !important;
    background-size: cover !important;
}
/* //moviles */
@media only screen and (max-width: 800px) {
	#content-time-line-tracking{
		padding: 0px 2rem;
	}
}
</style>


<template>
	<div>
    
		<div class="grid grid-nogutter surface-section text-800">
			
			<div class="md:col-8 overflow-hidden pages-body login-page flex flex-column">
			<!-- <div class="md:col-8 overflow-hidden pages-login flex flex-column"> -->
				<!-- <img src="demo/images/blocks/hero/hero-1.png" alt="Image" class="md:ml-auto block md:h-full" style="clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 100%)"> -->
			</div>
			<div class="col-12 sm:col-12 md:col-4 lg:px-8 text-center md:text-left flex align-items-center xl:px-4">
				<section class="md:mx-3 md:px-3 lg:px-3 lg:mx-3 px-auto mx-auto  xl:px-6 col-12 px-5">
				<div class="text-center mb-5 mx-auto px-auto">
					<router-link class="layout-topbar-logo" to="/">
					<img src="layout/images/logo-seway-plus.png" alt="Image" height="140" class="mb-3 mx-auto">
					</router-link>
					<div class="text-900 text-2xl font-medium mb-3">Bienvenido</div>
					<span class="text-600 font-medium line-height-3">No tienes una cuenta?</span>
					<a @click="openRegister" class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Crearla ahora!</a>
				</div>
			
				<div>
					<label for="email1" class="block text-900 font-medium mb-2">	</label>
					<div class="p-inputgroup">
						<span class="p-inputgroup-addon">
							<i class="pi pi-envelope"></i>
						</span>
						<span class="p-float-label">
							<InputText type="text" id="email" placeholder="Email" class="w-full" v-model="frmUsuarios.email"/>
						</span>
					</div>

					<label for="password1" class="block text-900 font-medium mb-2 mt-3">Password</label>
					
						<div class="p-inputgroup mb-3">
						<span class="p-inputgroup-addon">
							<i class="pi pi-lock"></i>
						</span>
						<span class="p-float-label">
							<InputText id="txtPassword" type="password" class="w-full" placeholder="Contraseña" @keyup.enter="login" v-model="frmUsuarios.password"/>
							<!-- <label for="password">Password</label> -->
						</span>
						<Button icon="pi pi-eye" id="show_password" type="button" @click="mostrarPassword">
						</Button>
					</div>

					<div class="flex align-items-center justify-content-between mb-3">
						<div class="flex align-items-center">
							<Checkbox id="rememberme1" :binary="true" v-model="frmUsuarios.auth_notification" class="mr-2"></Checkbox>
							<label for="rememberme1">Recordarme</label>
						</div>
						<a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer" @click="openModal">Olvidaste tu contraseña?</a>
					</div>
					<template v-if="loader==true">
						<center><i class="pi pi-spin pi-spinner mb-2" style="font-size: 2rem"></i></center>
					</template>
					<Button id="buttonlogin" label="Login" icon="pi pi-user" class="w-full mt-3" @click.prevent="login"></Button>
				</div>
				</section>
				<Dialog header="Recuperar contraseña" v-model:visible="display" :breakpoints="{'960px': '85vw'}" :style="{width: '30vw'}" :modal="true" class="p-fluid">
                        <p>Por favor ingrese su email</p>
                        <div class="grid formgrid">
                            <div class="col-12 mb-2 lg:col-12">
                                <span class="p-float-label">
                                    <InputText type="text" id="email" placeholder="Email" v-model="frmUsuarios.email"/>
                                </span>
                            </div>
                            
                        </div>
                        <template v-if="loader==true">
                                <center><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></center>
                            </template>
                        <template #footer>
                            <Button label="Enviar" @click="recuperarContraseña" icon="pi pi-check" class="p-button-raised p-button-primary"/>
                        </template>
                    </Dialog>
					<Dialog header="Seleccione el rol" v-model:visible="modalselectrol" :breakpoints="{'960px': '85vw'}" :style="{width: '30vw'}" :modal="true" class="p-fluid" :closable="false">
                        <!-- <p>Por favor ingrese su email</p> -->
                        <div class="grid formgrid">
                            <div class="col-12 mb-2 lg:col-12">
                                <div class="field mt-2">
									<!-- <label for="inventoryStatus" class="mb-3">Seleccionar rol</label> -->
									<Dropdown v-model="selectrol" :options="roles" optionLabel="description" placeholder="Seleccionar una empresa" />
									<small class="p-invalid p-error" v-if="!selectrol">Selección de rol es requerido.</small>
								</div>
                            </div>
                        </div>
                        <template v-if="loader==true">
							<div class="grid formgrid">
								<div class="col-12 mb-2 lg:col-12">
								<ProgressBar mode="indeterminate" style="height: .5em" />
								</div>
							</div>
						</template>
                        <template #footer>
                            <Button label="Ir al panel" :loading="loader" @click="rolselected" icon="pi pi-home" class="p-button-raised p-button-primary"/>
                        </template>
                    </Dialog>
					<Toast />
			</div>
		</div>
    </div>
</template>

<script>
// import ProductService from '../../service/ProductService';
// import CarouselDoc from "./CarouselDoc";
// import { APIENDPOINTIMG } from '../app.config'
import EventBus from '../event-bus';
import axios from 'axios'
export default {
	data() {
		return {
			frmUsuarios: {
				name: '',
				last_name: '',
				email: '',
				password: '',
				password_confirm: '',
				auth_notification: false,
				terms_conditions: ''
			},
			haytoken: '',
			listUsuarios: [],
			message: [],
			registro: false,
			loader: false,
			modalselectrol: false,
			selectrol: null,
			roles: [],
			count: 0,
			checked: '',
			display: false,
			responsiveOptions: [
				{
					breakpoint: '1024px',
					numVisible: 3,
					numScroll: 3
				},
				{
					breakpoint: '600px',
					numVisible: 2,
					numScroll: 2
				},
				{
					breakpoint: '480px',
					numVisible: 1,
					numScroll: 1
				}
			]
		}
	},
    productService: null,
	created() {
        var input = document.getElementById("email");
		console.log(input);
		if (input) {
			input.addEventListener("keyup", function(event) {
				console.log(event);
				if (event.keyCode === 13) {
					event.preventDefault();
					document.getElementById("buttonlogin").click();
				}
			});
		}
	},
	mounted() {
        // this.productService.getProductsSmall().then(data => this.products = data.slice(0,9));
	},
	setup() {
        var input = document.getElementById("email");
		console.log(input);
		if (input) {
			input.addEventListener("keyup", function(event) {
				console.log(event);
				if (event.keyCode === 13) {
					event.preventDefault();
					document.getElementById("buttonlogin").click();
				}
			});
		}
		
	},
	components: {
		// 'CarouselDoc': CarouselDoc
	},
	methods: {
            login(){
                
                // document.cookie = "username=John Doe";
                var buttonlogin = document.getElementById('buttonlogin');
			
                // console.log(this.frmUsuarios);
                if(!this.frmUsuarios.email || !this.frmUsuarios.password){
                    var error = 'Debe ingresar usuario y contraseña'
                    this.showError(error);
                    // buttonlogin.removeAttribute('disabled');
                }else{
                
                    buttonlogin.setAttribute("disabled", "");
                    var _that = this;
                    this.loader = true;
                   var url = '/login';
					
					window.api.call('post', url, {email: this.frmUsuarios.email,password: this.frmUsuarios.password}).then( function(response) {
						if(response.status == 200){
							if(response.data.verificado == null){
								_that.loader = false;
								axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
								axios.defaults.headers.common['Email'] = 'Bearer ' + response.data.email;
								document.cookie = "Nombre="+response.data.nombre;
								document.cookie = "Email="+response.data.email;
								
								_that.$store.dispatch('infoNavigation/getAccess','Bearer '+response.data.access_token);
								_that.$router.push({ path: '/access' });
								_that.clearForm();
								buttonlogin.removeAttribute('disabled');
							}else{
								if(response.data.rol1.length == 1){
									// console.log(response.data.access_token);
									_that.$store.dispatch('infoNavigation/getAccess','Bearer '+response.data.access_token);
									_that.$store.dispatch('infoNavigation/getIdRol',response.data.rol);
									document.cookie = "Nombre="+response.data.nombre;
									document.cookie = "Email="+response.data.email;
									document.cookie = "ImgUser="+response.data.avatar;
									document.cookie = "IdRol="+response.data.rol;
									EventBus.emit('show-container',response.data.rol);

									_that.showSuccess();
									_that.loader = false;
									_that.clearForm();
									
									if(response.data.rol == 1){
										_that.$store.dispatch('infoNavigation/getRol','/especiales');
										_that.$router.push({path: '/especiales'});
										
									}else if (response.data.rol == 2){
										document.cookie = "Rol="+'/generador';
										_that.$store.dispatch('infoNavigation/getRol','/generador');
										_that.$router.push({path: '/generador'});
										
									}else if (response.data.rol == 3){
										document.cookie = "Rol="+'/proveedor';
										_that.$store.dispatch('infoNavigation/getRol','/proveedor');
										_that.$router.push({path: '/proveedor'});
										
									}else if (response.data.rol == 4){
										document.cookie = "Rol="+'/terceros';
										_that.$store.dispatch('infoNavigation/getRol','/terceros');
										_that.$router.push({path: '/terceros'});

									}else if (response.data.rol == 5){
										document.cookie = "Rol="+'/empresa-transporte';
										_that.$store.dispatch('infoNavigation/getRol','/empresa-transporte');
										_that.$router.push({path: '/empresa-transporte'});
										
									}else{
										_that.$router.push({path: '/'});
									}
									buttonlogin.removeAttribute('disabled');
								}
								if(response.data.rol1.length > 1){
									_that.modalselectrol = true;
									_that.$store.dispatch('infoNavigation/getAccess','Bearer '+response.data.access_token);
									document.cookie = "Nombre="+response.data.nombre;
									document.cookie = "Email="+response.data.email;
									document.cookie = "ImgUser="+response.data.avatar;
									_that.showSuccess();
									_that.loader = false;
									_that.clearForm();
									// console.log(_that.modalselectrol);
									_that.roles = response.data.rol1;
								}
								if(response.data.rol == 0){
									_that.$router.push({path: '/pendiente-activacion'});
								}

								
							}
						}
					}).catch(function (error) {
							// if (error.response) {
							// console.log(error.data.message);
							// console.log(error.response.data);
							// console.log(error.response.status);
							// buttonlogin.removeAttribute('disabled');
							// console.log(error.response.headers);
							_that.showError(error.data.message);
							_that.loader = false;
							// } else if (error.request) {
								buttonlogin.removeAttribute('disabled');
							// // The request was made but no response was received
							// console.log(error.request);
							// _that.loader = false;
							// } else {
							// // Something happened in setting up the request that triggered an Error
							// console.log('Error', error.message);
							// buttonlogin.removeAttribute('disabled');
							// _that.loader = false;
							// }

						});
                }

				
            },
			rolselected(){
				var _that = this;
				this.loader = true;
				if(!this.selectrol){
                    this.$toast.add({severity:'error', summary: 'Error en envio', detail:'Debes seleccionar un rol', life: 5000});
                }else{
					// console.log(this.selectrol);
					document.cookie = "IdRol="+this.selectrol.id_role;
					_that.$store.dispatch('infoNavigation/getIdRol',this.selectrol.id_role);
					EventBus.emit('show-container',this.selectrol.id_role);
					this.loader = false;

					if(this.selectrol.id_role == 1){
						_that.$store.dispatch('infoNavigation/getRol','/especiales');
						
						_that.$router.push({path: '/especiales'});
						
					}else if (this.selectrol.id_role == 2){
						_that.$store.dispatch('infoNavigation/getRol','/generador');
						document.cookie = "Rol="+'/generador';
						_that.$router.push({path: '/generador'});
						
					}else if (this.selectrol.id_role == 3){
						_that.$store.dispatch('infoNavigation/getRol','/proveedor');
						document.cookie = "Rol="+'/proveedor';
						_that.$router.push({path: '/proveedor'});
						
					}else if (this.selectrol.id_role == 4){
						_that.$store.dispatch('infoNavigation/getRol','/terceros');
						document.cookie = "Rol="+'/terceros';
						_that.$router.push({path: '/terceros'});
						
					}else if (this.selectrol.id_role == 5){
						_that.$store.dispatch('infoNavigation/getRol','/empresa-transporte');
						document.cookie = "Rol="+'/empresa-transporte';
						_that.$router.push({path: '/empresa-transporte'});
						
					}else{
						_that.$router.push({path: '/'});
					}
				}
			},
			openRegister(){
                this.$router.push({ path: '/info-generadores' });
                // this.$router.push({ path: '/pasarela' });
            },
			mostrarPassword(){
                var cambio = document.getElementById("txtPassword");
                var show_password;
                if(cambio.type == "password"){
                    cambio.type = "text";
                    show_password = document.querySelector("#show_password > span.pi.pi-eye.p-button-icon");
                    show_password.classList.remove('pi-eye');
                    show_password.classList.add('pi-eye-slash'); 
                }else{
                    cambio.type = "password";
                    show_password = document.querySelector("#show_password > span.pi.pi-eye-slash.p-button-icon");
                    show_password.classList.remove('pi-eye-slash');
                    show_password.classList.add('pi-eye'); 
                }
            },
            clearForm(){
                this.frmUsuarios.name = "";
                this.frmUsuarios.last_name = "";
                this.frmUsuarios.email = "";
                this.frmUsuarios.password = "";
                this.frmUsuarios.password_confirm = "";
            },
            recuperarContraseña(){
                if(!this.frmUsuarios.email){
                    this.$toast.add({severity:'error', summary: 'Error en envio', detail:'Asegurese de que su correo este correctamente escrito', life: 5000});
                }else{
                this.loader = true;
                var _that = this;
                axios.post('/passwordreset', {email: this.frmUsuarios.email}).then((response) => {
                            // console.log(response.data.message);
                            _that.$toast.add({severity:'success', summary: 'Envio exitoso', detail:'por favor seguir las indicaciones que hemos enviado a su correo', life: 5000});
                            _that.loader = false;
                            // _that.registro = false;
                            _that.closeModal();
                            
                        }).catch(function (error) {
                        if (error.response) {
                        // Request made and server responded
                        // console.log(error.response.data);
                        // console.log(error.response.status);
                        // console.log(error.response.headers);
                        _that.loader = false;
                        // alert(error.response.data.message);
                        _that.showError(error.response.data.message);
                        } else if (error.request) {
                            _that.loader = false;
                        // The request was made but no response was received
                        // console.log(error.request);
                        } else {
                            _that.loader = false;
                        // Something happened in setting up the request that triggered an Error
                        // console.log('Error', error.message);
                        }
                    });
                }
            },
			openModal() {
				this.display = true;
			},
            closeModal() {
				this.display = false;
			},
            showSuccess() {
				this.$toast.add({severity:'success', summary: 'Ingreso exitoso', detail:'Bienvenido', life: 5000});
			},
            showError(error) {
                // this.message = [{severity: 'error', detail: 'Error Message', content: error.response.data.message, id: this.count++}]
                this.$toast.add({severity:'error', summary: 'Error', detail:error, life: 5000});
            },
	}
}
</script>

<style lang="scss" scoped>
.product-item {
    .product-item-content {
        border: 1px solid var(--surface-border);
        border-radius: 3px;
        margin: .3rem;
        text-align: center;
        padding: 2rem 0;
    }
    .product-image {
        width: 50%;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
    }
}
.pages-login{
	background: url("https://it.cargacontrol.com.co/wp-content/uploads/2021/03/Mesa-de-trabajo-1-copia-8-80.jpg");
    background-size: cover;
	background-position: center;
}
</style>

<template>
<div class="pages-body login-page flex flex-column align-items-center">
    <!-- <div>
        <ProgressBar mode="indeterminate" style="height: .5em" />
    </div> -->
    <div class="mt-5">
        <!-- <center> -->
            <ProgressBar mode="indeterminate" style="height: .5em" class="mb-3" />
            <img id="logo" src="layout/images/seway-blanco.png" alt="ultima-layout" style="height: 4.25rem">
        <!-- </center> -->
    </div>
</div>
  
</template>

<script>
export default {
    data() {
        return {
            isMenuActive: false
        }
    },
    methods:  {
        onMenuButtonClick(e) {
            let menu = document.getElementById('menu');

            if (this.isMenuActive) {
                this.addClass(menu, 'fadeOutUp');
                this.isMenuActive = false;
            }
            else {
                this.addClass(menu, 'menu-active fadeInDown');
                this.isMenuActive = true;
            }
            e.preventDefault();
        },

        smoothScroll(id){
            document.querySelector(id).scrollIntoView({
                behavior: 'smooth'
            });
        },

        addClass(element, classNames) {
            let classNamesArr = classNames.split(' ');
            for (var i = 0; i < classNamesArr.length; i++) {
                let className = classNamesArr[i];
                if (element.classList)
                    element.classList.add(className);
                else
                    element.className += ' ' + className;
            }
        },

        removeClass(element, classNames) {
            let classNamesArr = classNames.split(' ');
            for (var i = 0; i < classNamesArr.length; i++) {
                let className = classNamesArr[i];
                if (element.classList)
                    element.classList.remove(className);
                else
                    element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
export default { // methods
    updateAccess(state, data) {
        state.access = data;
    },
    updateRol(state, data) {
        state.rol = data;
    },
    updateIdRol(state, data) {
        state.IdRol = data;
    },
    updateUser(state, data) {
        state.user = data;
    },
    updateLoader(state, data) {
        state.loader = data;
    }
}
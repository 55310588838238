export default { // methods
    access(state) {
        return state.access;
    },
    rol(state) {
        return state.rol;
    },
    IdRol(state) {
        return state.IdRol;
    },
    user(state) {
        return state.user;
    },
    loader(state) {
        return state.loader;
    }
}
<template>
<div class="landing-container">
    <Header />

    <div id="banner-seway" class="flex flex-column">
        
        <div class="grid pt-3 lg-mt-5 container-banner-seway py-8" >
            <div class="col-7 lg:col-7  flex flex-column justify-content-center ">
                <div class="mx-8 col-8 text-center">
                    <Pasarela />
                </div>
               <!-- <img id="mano" src="layout/images/mano.png" alt="ultima-layout" class="ml-5"> -->
               
            </div>
            <div class="col-5 lg:col-5 flex flex-column justify-content-center ">
                <!-- <blockquote class="hr-vertical-banner pr-1"><p class="title-banner">Información<br> <strong>Proveedores</strong></p></blockquote> -->
                <div class=" col-8 text-center">
                    <!-- <Pasarela /> -->
                    <!-- <router-link to="/registrar-servicios-especiales">
                    <Button label="Registrarse" class=" mb-3 button-banner rounded" to="../../#/login"/>
                    </router-link> -->
                </div>
            </div>
        </div>
    </div>
    <Footer />
        </div>

</template>

<script>
import Header from '../../landing/Header';
import Footer from '../../landing/Footer';
import Pasarela from '../../pages/PasarelaRegistro';
export default {
    data() {
        return {
            isMenuActive: false,
            nestedMenuitems: this.$primevue.config.menu
        }
    },
    mounted(){
        // console.log(screen.width);
    },
    methods:  {
        onMenuButtonClick(e) {
            let menu = document.getElementById('menu');

            if (this.isMenuActive) {
                this.addClass(menu, 'fadeOutUp');
                this.isMenuActive = false;
            }
            else {
                this.addClass(menu, 'menu-active fadeInDown');
                this.isMenuActive = true;
            }
            e.preventDefault();
        },

        smoothScroll(id){
            document.querySelector(id).scrollIntoView({
                behavior: 'smooth'
            });
        },
        resolution_pantallas(){
            // console.log(screen.width);
            return screen.width;
        },
        toggleMenu(event) {
				this.$refs.menu.toggle(event);
			},

        addClass(element, classNames) {
            let classNamesArr = classNames.split(' ');
            for (var i = 0; i < classNamesArr.length; i++) {
                let className = classNamesArr[i];
                if (element.classList)
                    element.classList.add(className);
                else
                    element.className += ' ' + className;
            }
        },

        removeClass(element, classNames) {
            let classNamesArr = classNames.split(' ');
            for (var i = 0; i < classNamesArr.length; i++) {
                let className = classNamesArr[i];
                if (element.classList)
                    element.classList.remove(className);
                else
                    element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }
    },
    components: {
            "Header": Header,
            "Footer": Footer,
            "Pasarela": Pasarela
    }
}
</script>

<style lang="scss" scoped>
.videoFrame{
    width: 560px;
    height: 300px;
    
    @media screen and (max-width: 960px) {
        width: 300px;
    }
}
#btn-registrar{
    color: #0A354E;
    background: #ffffff;
}
.container-banner-seway{
    margin-right: 0rem;
    // background: url(https://erp.seway.com.co/layout/images/fondo-banner.png);
    background: url(https://sewayerpqa.seway.com.co/layout/images/pages/rol-terceros.png);
    // background: url(https://erp.seway.com.co/layout/images/pages/rol-terceros.png);
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 7rem !important;
}
#img-section-1{
    width: 30rem;
    float: right;
}
#img-section-2{
    width: 20rem;
    float: right;
}
.header-landing-seway{
    position: fixed;
    z-index: 1000;
    border-bottom: 3px solid #FFFFFF;
    border-radius: 0px 0px 5px 5px;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(255,255,255,1);
    -moz-box-shadow: 0px 0px 5px 1px rgba(255,255,255,1);
    box-shadow: 0px 0px 5px 1px rgba(255,255,255,1);
}
.p-menubar {
  padding: 0.75rem;
  background: transparent;
//   color: rgba(255, 255, 255, 0.87);
  border: 1px transparent;
  border-radius: 4px;
}
// .container-banner-seway{
//     margin-right: 0rem;
//     margin-top: 10rem !important;
// }
.title-marcas-confian{
    color: #0A354E;
    font-size: 2.5rem;
    line-height: normal;
}
.title-banner{
    color: #0A354E;
    font-size: 3.5rem;
    line-height: normal;
}
.hr-vertical-banner{
    border-left: 4px solid #EB7D10;
}
.hr-vertical-quienes-somos{
    border-left: 4px solid #0A354E;
    padding-left: 3rem !important;
}
.quienes-somos-item{
    color: #0A354E;
    font-size: 1.20rem;
    font-weight: 400;
}
.title-ventajas{
    color: #0A354E;
    font-size: 2.25rem;
    line-height: normal;
}
.title-quienes-somos{
    color: #0A354E;
    font-size: 2.5rem;
    font-weight: 800;
}
.description-quienes-somos{
    font-size: 1.5rem;
}
.container-list-ventajas{
    line-height: 3em;
}
.listado-ventajas{
    color: #0A354E;
    font-size: 1.3rem;
    line-height: 0.5;
}
.listado-usos{
    color: #0A354E;
    font-size: 1.3rem;
    line-height: 0.5;
}
.section-precios{
    background: #0A354E;
}
.titulo-precios{
    color: white;
    font-size: 2rem;
    font-weight: 800;
}
.titulo-plan-precios{
    color: #EB7D10;
    font-size: 2.5rem;
    font-weight: 800;
}
.precio-plan-precios{
    color: #EB7D10;
    // font-size: 2.5rem;
    // font-weight: 800;
}
.buttom-obtener{
    background: #EB7D10;
    color: white;
    width: 50%;
}
.buttom-obtener:hover{
    background: #ffffff;
    color: #0A354E;
    width: 50%;
}
.button-banner{
    font-weight: 800;
    background: #EB7D10;
    color: white;
    font-size: 1.5rem;
}
.button-menu-seway{
    background: #EB7D10;
    color: white;
}
.button-menu-seway:hover{
    background: #0A354E;
    color: white;
}
.button-banner:hover{
    font-weight: 800;
    background: #0A354E;
    color: white;
    font-size: 1.5rem;
}
.container-precios{
    border: 1px solid #EB7D10;
    border-radius: 15px;
    height: 100%;
}
.container-precios:hover{
    transition: 0.5s;
    -webkit-box-shadow: 0px 0px 42px 4px rgba(235,125,16,1);
    -moz-box-shadow: 0px 0px 42px 4px rgba(235,125,16,1);
    box-shadow: 0px 0px 42px 4px rgba(235,125,16,1);
}
#mano{
        width: 19rem;
    }
//moviles
@media only screen and (max-width: 800px) {
    .title-banner{
        color: #0A354E;
        font-size: 1.5rem;
        line-height: normal;
        
    }
    .hr-vertical-banner{
        border-left: 4px solid #EB7D10;
        margin-left: 20%;
    }
    #mano{
        width: 9rem;
    }
    .button-banner{
        font-weight: 200;
        background: #EB7D10;
        color: white;
        font-size: 1rem;
        margin-left: 17px;
    }
    .button-banner:hover{
        font-weight: 200;
        background: #0A354E;
        color: white;
        font-size: 1rem;
        margin-left: 17px;
    }
    .landing-container #header .header-menu-container {
        z-index: 100;
        position: fixed;
        top: 0;
        left: 0;
        // width: 73%;
        background: #212121;
        padding: 2rem 1rem;
    }
    #img-section-1{
        width: 10rem;
        float: right;
    }
    #img-section-2{
        width: 8rem;
        float: right;
    }
    .title-ventajas{
        color: #0A354E;
        font-size: 1.5rem;
        line-height: normal;
    }
    .listado-ventajas{
        color: #0A354E;
        font-size: 1rem;
        line-height: 1;
    }
    .container-banner-seway{
        margin-right: 0rem;
        margin-top: 2rem !important;
    }
    .hr-vertical-quienes-somos{
        border-left: 4px solid #0A354E;
        padding-left: 0rem !important;
    }
    #content-ventajas{
        padding-left: 4%;
    }
}
</style>
<template>
	<div class="pages-body login-page flex flex-column">
    <!-- <div class="topbar p-3 flex justify-content-between flex-row align-items-center">
        <router-link class="topbar-left ml-3 flex" to="/">
            <div class="logo">
                <img src="assets/layout/images/systram-azul.png" alt=""/>
            </div>
        </router-link> -->
        <!-- <router-link class="topbar-right mr-3 flex" to="/">
            <Button type="button" label="DASHBOARD" class="p-button-text p-button-plain topbar-button"></Button>
        </router-link> -->
    <!-- </div> -->

    <div class="align-self-center mt-auto mb-auto">
        <div class="pages-panel card flex flex-column">
            <!-- LOGIN! -->
            <div class="pages-header px-3 py-1">
                <h2>ACTUALIZAR CONTRASEÑA</h2>
            </div>
            <Toast />
            <h4>Reestablecer contraseña | Seway</h4>
        
            <div class="pages-detail mb-6 px-6">Por favor use el formulario para reestablecer su contraseña</div>

            <div class="input-panel flex flex-column px-3">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-lock"></i>
                    </span>
                    <span class="p-float-label">
                        <InputText type="password" id="password" placeholder="Nueva contraseña" v-model="frmUsuarios.password"/>
                    </span>
                </div>

                <div class="p-inputgroup mt-3 mb-3">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-lock"></i>
                    </span>
                    <span class="p-float-label">
                        <InputText type="password" id="txtPassword" placeholder="Confirme su nueva contraseña" v-model="frmUsuarios.password_confirm"/>
                        <!-- <label for="password">Password</label> -->
                    </span>
                </div>
                <!-- <div class="flex align-items-center justify-content-between mb-3">
                    <div class="flex align-items-center">
                        <Checkbox id="rememberme1" :binary="true" v-model="checked" class="mr-2"></Checkbox>
                        <label for="rememberme1">Remember me</label>
                    </div>
                    <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer" @click="openModal">¿Se te olvidó tu contraseña?</a>
                    <Dialog header="Recuperar contraseña" v-model:visible="display" :breakpoints="{'960px': '85vw'}" :style="{width: '30vw'}" :modal="true">
                        <p>Por favor ingrese su email</p>
                        <div class="grid formgrid">
                            <div class="col-12 mb-2 lg:col-4 lg:col-4">
                                <span class="p-float-label">
                                    <InputText type="text" id="email" placeholder="Email" v-model="frmUsuarios.email"/>
                                </span>
                            </div>
                            <template v-if="loader==true">
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            </template>
                        </div>
                        <template #footer>
                            <Button label="Enviar" @click="recuperarContraseña" icon="pi pi-check" class="p-button-secondary"/>
                        </template>
                    </Dialog>
                </div> -->
            </div>
            <!-- <transition-group name="p-message" tag="div">
                <Message v-for="msg of message" :severity="msg.severity" :key="msg.id"><b>{{msg.detail}}</b> {{msg.content}}</Message>
            </transition-group> -->
            <template v-if="loader==true">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            </template>
            <Button class="login-button mb-3 px-3 mt-3" label="REESTABLECER CONTRASEÑA" @click.prevent="resetPassword"></Button>
		<!-- <Button @click="openRegister" label="Registrarse" class="p-button-success" ></Button> -->

            
       <Toast />
        </div>
    </div>
</div>

</template>

<script>
import axios from 'axios'
	export default {
		data() {
			return {
				frmUsuarios: {
                    name: '',
                    last_name: '',
                    email: '',
                    password: '',
                    password_confirm: '',
                    auth_notification: '',
                    terms_conditions: ''
                },
                haytoken: '',
                listUsuarios: [],
                message: [],
                registro: false,
                loader: false,
                count: 0,
                checked: '',
                display: false,
			}
		},
        methods: {
            resetPassword(){
                if(!this.frmUsuarios.password || !this.frmUsuarios.password_confirm){
                    this.$toast.add({severity:'error', summary: 'Error', detail:'Debe llenar ambos campos!', life: 3000});
                }else{
                    if(this.frmUsuarios.password != this.frmUsuarios.password_confirm){
                        // error = 'Las contraseñas no coinciden!'
                        this.$toast.add({severity:'error', summary: 'Error', detail:'Las contraseñas no coinciden!', life: 3000});

                    }else{
                        var _that = this;
                        this.loader = true;
                        const email = this.$route.query.email;
                        const token = this.$route.query.token;
                        console.log(email);
                        console.log(token);
                        axios.post('/reset-password',{email: email,token: token,password: this.frmUsuarios.password,password_confirmation: this.frmUsuarios.password_confirm}).then((response) => {
                                console.log(response.data.message);
                                // _that.clearForm();
                                // _that.$router.push({ path: '/login' });
                                _that.$toast.add({severity:'success', summary: 'Reestablecimiento exitoso', detail:response.data.message, life: 5000});
                                // _that.$router.push({ path: '/login' });
                                _that.loader = false;
                                setTimeout( function() { _that.$router.push({ path: '/login'})},4000);
                                    
                            }).catch(function (error) {
                            if (error.response) {
                            // Request made and server responded
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                            _that.$toast.add({severity:'error', summary: 'Error', detail:error.response.data.message, life: 5000});

                            // alert(error.response.data.message);
                            // _that.message = [{severity: 'error', detail: 'Error Message', content: error.response.data.message, id: this.count++}]
                            // _that.showError(error.response.data.message);
                            _that.loader = false;
                            } else if (error.request) {
                            // The request was made but no response was received
                            console.log(error.request);
                            _that.loader = false;
                            } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                            _that.loader = false;
                            }

                        });
                    }
                }
            },
        }
	}
</script>

<style scoped>

</style>

<template>
<div class="landing-container">
   <template  v-if="resolution_pantallas()>800">
        <div id="header" class="section flex flex-column header-landing-seway">
            <div class="header-menu-container flex align-items-center justify-content-between">
                <a class="layout-topbar-logo" href="/#/">
                    <img id="logo" src="layout/images/seway-blanco.png" alt="ultima-layout" style="height: 3.5rem">
                </a>
                <Menubar :model="nestedMenuitems">
                    </Menubar>
            </div>
        </div>
    </template>
    <template v-else>
        <div id="header" class="section flex flex-column">
            <div class="header-menu-container flex align-items-center justify-content-between">
                <a class="layout-topbar-logo" href="/">
                    <img id="logo" src="layout/images/seway-blanco.png" alt="ultima-layout" style="height: 3.5rem">
                </a>

                <Menu ref="menu" :model="nestedMenuitems" :popup="true" />
                    <Button label="Menu" icon="pi pi-angle-down" @click="toggleMenu" style="width: auto" class="button-menu-seway"/>
            </div>

        </div>
    </template>
</div>

</template>

<script>
export default {
    data() {
        return {
            isMenuActive: false,
            nestedMenuitems: this.$primevue.config.menu
        }
    },
    mounted(){
        // console.log(screen.width);
    },
    methods:  {
        onMenuButtonClick(e) {
            let menu = document.getElementById('menu');

            if (this.isMenuActive) {
                this.addClass(menu, 'fadeOutUp');
                this.isMenuActive = false;
            }
            else {
                this.addClass(menu, 'menu-active fadeInDown');
                this.isMenuActive = true;
            }
            e.preventDefault();
        },

        smoothScroll(id){
            document.querySelector(id).scrollIntoView({
                behavior: 'smooth'
            });
        },
        resolution_pantallas(){
            // console.log(screen.width);
            return screen.width;
        },
        toggleMenu(event) {
				this.$refs.menu.toggle(event);
			},

        addClass(element, classNames) {
            let classNamesArr = classNames.split(' ');
            for (var i = 0; i < classNamesArr.length; i++) {
                let className = classNamesArr[i];
                if (element.classList)
                    element.classList.add(className);
                else
                    element.className += ' ' + className;
            }
        },

        removeClass(element, classNames) {
            let classNamesArr = classNames.split(' ');
            for (var i = 0; i < classNamesArr.length; i++) {
                let className = classNamesArr[i];
                if (element.classList)
                    element.classList.remove(className);
                else
                    element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.header-landing-seway{
    position: fixed;
    z-index: 1000;
    border-bottom: 3px solid #FFFFFF;
    padding: 1.5rem 15rem;
    background: linear-gradient(90deg, rgba(2,0,36,1) 25%, rgba(10,53,78,1) 75%, rgba(0,141,170,1) 100%) !important;
    border-radius: 0px 0px 5px 5px;
    background: linear-gradient(90deg, rgba(2,0,36,1) 25%, rgba(10,53,78,1) 75%, rgba(0,141,170,1) 100%) !important;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(255,255,255,1);
    -moz-box-shadow: 0px 0px 5px 1px rgba(255,255,255,1);
    box-shadow: 0px 0px 5px 1px rgba(255,255,255,1);
}
.p-menubar {
  padding: 0.75rem;
  background: transparent;
//   color: rgba(255, 255, 255, 0.87);
  border: 1px transparent;
  border-radius: 4px;
}
.button-menu-seway{
    background: #EB7D10;
    color: white;
}
.button-menu-seway:hover{
    background: #0A354E;
    color: white;
}

//moviles
@media only screen and (max-width: 800px) {
    .landing-container #header .header-menu-container {
        z-index: 100;
        position: fixed;
        top: 0;
        left: 0;
        // width: 73%;
        background: #212121;
        padding: 2rem 1rem;
    }
}
</style>
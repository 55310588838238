
<template>
	<div>
    
		<div class="grid grid-nogutter surface-section text-800">
			
			<!-- <div class="md:col-7 pages-body login-page flex flex-column" style="overflow-y: auto;height: 880px;"> -->
			<div class="md:col-7 pages-body login-page flex flex-column">
			<!-- <div class="md:col-8 overflow-hidden pages-login flex flex-column"> -->
				<!-- <img src="demo/images/blocks/hero/hero-1.png" alt="Image" class="md:ml-auto block md:h-full" style="clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 100%)"> -->
			</div>
			<div class="col-12 sm:col-12 md:col-5 lg:px-7 text-center md:text-left flex align-items-center xl:px-2">
				<section class="md:mx-3 md:px-3 lg:px-3 lg:mx-2 px-auto mx-auto  xl:px-5 col-12 px-5">
				<div class="text-center mb-5 mx-auto px-auto">
					<!-- <img src="https://systramqa.systram.com.co/presentacion/imagenes/Banners/Logo-Systram.png" alt="Image" height="80" class="mb-3 mx-auto"> -->
                    <!-- <img src="layout/images/logo-seway-plus.png" alt="Image" height="110" class="mb-3 mx-auto">
					<div class="text-900 text-2xl font-medium mb-3">Registro para <span class="person-tag teal-bgcolor white-color p-1">Generador</span></div> -->
                    <p class="title-register mt-4">REGISTRO PARA <br><strong style="color: #EB7D10;">GENERADORES</strong></p>
					<span class="text-600 font-medium line-height-3">Tienes una cuenta?</span>
					<a @click="openRegister" class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Ingresa ahora!</a>
				</div>

				<div class="input-panel flex grid">
                    <div class="col-12 md:col-6 xl:col-6">
                        
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-user"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText type="text" id="nombre" class="w-full" v-model="frmUsuarios.name" :class="{'p-invalid': submitted && !frmUsuarios.name}" @click="validarcampo(frmUsuarios.name)"/>
                                <!-- <InputText id="nombre" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted}" aria-describedby="email-error"/>
                                <label for="email" :class="{'p-error':v$.email.$invalid && submitted_email}">Email*</label> -->
                                <label for="nombre">Nombres*</label>
                            </span>
                        </div>
                        <small class="p-invalid p-error" v-if="!frmUsuarios.name && showcamposvacios==true">Nombre es requerido.</small>
                    </div>
                    <div class="col-12 md:col-6 xl:col-6">
                        
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-user"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText type="text" id="apellido" class="w-full" v-model="frmUsuarios.last_name" :class="{'p-invalid': submitted && !frmUsuarios.last_name}" @click="validarcampo(frmUsuarios.last_name)"/>
                                <label for="apellido">Apellidos*</label>
                            </span>
                        </div>
                        <small class="p-invalid p-error" v-if="!frmUsuarios.last_name && showcamposvacios==true">Apellido es requerido.</small>
                    </div>

                    <div class="col-12 md:col-12 xl:col-12">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-envelope"></i>
                            </span>
                            <span class="p-float-label">
                                <!-- <InputText type="email" id="email" placeholder="Email" class="w-full" v-model="frmUsuarios.email" :class="{'p-invalid': submitted && !frmUsuarios.email}"/> -->
                                <InputText id="email" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted}" aria-describedby="email-error"/>
                                <label for="email" :class="{'p-error':v$.email.$invalid && submitted_email}">Email*</label>
                            </span>
                        </div>
                        <!-- <div class="p-float-label p-input-icon-right">
                            <i class="pi pi-envelope" />
                            <InputText id="email" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted}" aria-describedby="email-error"/>
                            <label for="email" :class="{'p-error':v$.email.$invalid && submitted_email}">Email*</label>
                        </div> -->
                        <span v-if="v$.email.$error && submitted_email">
                            <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                            <small class="p-error">{{mostrar_mensaje_esp(error.$message)}}</small>
                            <!-- <small class="p-error">{{error.$message}}</small> -->
                            <div id="google_translate_element"></div>
                            </span>
                        </span>
                        <!-- <small v-else-if="(v$.email.$invalid && submitted_email) || v$.email.$pending.$response" class="p-error">2{{v$.email.required.$message.replace('Value', 'Email')}}</small> -->
                        <small v-else-if="(v$.email.$invalid && submitted_email) || v$.email.$pending.$response" class="p-error">{{mostrar_mensaje_esp()}}</small>
                    </div>
                    <div class="col-12 md:col-6 xl:col-6 mt-3">
                        
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-map-marker"></i>
                            </span>
                            <span class="p-float-label">
                                <Multiselect v-model="frmUsuarios.ciudad" placeholder="Ciudad*" id="ciudad" :max="-1" valueProp="id" :options="zonas" :searchable="true" label="nombre_division" track-by="nombre_division" :class="{'multiselect-invalid': submitted && !frmUsuarios.ciudad}" />
                                <!-- <label for="ciudad">Ciudad</label> -->
                            </span>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 xl:col-6 mt-3">
                        
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-phone"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText type="number" id="cel" class="w-full" v-model="frmUsuarios.num_cel"/>
                                <!-- <InputMask mask="999-999-99-99" v-model="frmUsuarios.nit_transporte" placeholder="NIT de la empresa transportadora*" :class="{'p-invalid': submitted && !frmUsuarios.nit_transporte}"/> -->
                                <label for="cel">Número de Celular</label>
                            </span>
                        </div>
                    </div>

                    <div class="col-12 md:col-6 xl:col-6 mt-3">
                        <!-- <label for="tipo_documento" class="block text-900 font-medium mb-2">Tipo de Documento</label> -->
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-id-card"></i>
                            </span>
                            <span class="p-float-label">
                                <Dropdown v-model="selectipo_documento" :options="tipos_documentos" id="tipo_documento" :class="{'p-invalid': submitted && selectipo_documento==null}"/>
                                <label for="tipo_documento">Tipo de Documento*</label>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 xl:col-6 mt-3">
                        
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-id-card"></i>
                            </span>
                            <span class="p-float-label">
                                <template v-if="selectipo_documento=='NIT'">
                                    <InputNumber mode="decimal" :useGrouping="false" id="cedula" placeholder="NIT de la empresa*" class="w-full" v-model="frmUsuarios.nit" :class="{'p-invalid': submitted && !frmUsuarios.nit}"/>
                                </template>
                                <template v-else>
                                    <InputNumber mode="decimal" :useGrouping="false" id="cedula" class="w-full" v-model="frmUsuarios.cedula" :class="{'p-invalid': submitted && !frmUsuarios.cedula}"/>
                                </template>
                                <label for="cedula">Número de Documento*</label>
                            </span>
                        </div>
                    </div>

                    <div class="col-12 md:col-6 xl:col-6 mt-3">
                        
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-hashtag"></i>
                            </span>
                            <span class="p-float-label">
                                <!-- <InputMask mask="999.999.999" v-model="frmUsuarios.nit_transporte" placeholder="NIT de la empresa transportadora*" :class="{'p-invalid': submitted && !frmUsuarios.nit_transporte}"/> -->
                                <InputMask mask="999.999.999" v-model="frmUsuarios.nit_transporte" id="nit_custo" :class="{'p-invalid': submitted && !frmUsuarios.nit_transporte}"/>
                                <label for="nit_custo">NIT Empresa de Transporte*</label>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 xl:col-6 mt-3">
                        
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-hashtag"></i>
                            </span>
                            <span class="p-float-label">
                                <InputNumber mode="decimal" :useGrouping="false" id="nit_gene" class="w-full" v-model="frmUsuarios.nit" :class="{'p-invalid': submitted && !frmUsuarios.nit}"/>
                                <!-- <InputMask mask="999.999.999.999.999" v-model="frmUsuarios.nit" placeholder="NIT de la empresa*" :class="{'p-invalid': submitted && !frmUsuarios.nit}"/> -->
                                <label for="nit_gene">NIT Empresa Generadora*</label>
                            </span>
                        </div>
                    </div>

                    <div class="col-12 md:col-6 xl:col-6 mt-3">
                        
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-building"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText type="text" id="razon" class="w-full" v-model="frmUsuarios.razon_social" :class="{'p-invalid': submitted && !frmUsuarios.razon_social}"/>
                                <label for="razon">Razón Social</label>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 xl:col-6 mt-3">
                        
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-briefcase"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText type="text" id="cargo" class="w-full" v-model="frmUsuarios.cargo" :class="{'p-invalid': submitted && !frmUsuarios.cargo}"/>
                                <label for="cargo">Cargo*</label>
                            </span>
                        </div>
                    </div>

                    <div class="col-12 md:col-6 xl:col-6 mt-3 p-fluid">
                                <span class="p-float-label">
                                <Password v-model="frmUsuarios.password" id="password" toggleMask strongRegex="(?=.*?[8])" :class="{'p-invalid': submitted && !frmUsuarios.password}">
                                    <template #footer="sp">
                                        {{sp.level}}
                                        <Divider />
                                        <p class="mt-2">Parámetros</p>
                                        <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                            <li>Mínimo 8 dígitos</li>
                                        </ul>
                                    </template>
                                </Password>
                                <label for="password">Contraseña *</label>
                                </span>
                        <template v-if="frmUsuarios.password != frmUsuarios.password_confirm">
                            <small class="p-invalid p-error" >Las contraseñas no coinciden.</small>
                        </template>
                    </div>
                    <div class="col-12 md:col-6 xl:col-6 mt-3 p-fluid">
                            <span class="p-float-label">
                                <Password v-model="frmUsuarios.password_confirm" is="password2" toggleMask strongRegex="(?=.*?[8])" :class="{'p-invalid': submitted && !frmUsuarios.password_confirm}">
                                    <template #footer="sp">
                                        {{sp.level}}
                                        <Divider />
                                        <p class="mt-2">Parámetros</p>
                                        <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                            <li>Mínimo 8 dígitos</li>
                                            <li>Debe ser igual a la clave</li>
                                        </ul>
                                    </template>
                                </Password>
                                <label for="password2">Confirmar Contraseña </label>

                            </span>
                        <template v-if="frmUsuarios.password != frmUsuarios.password_confirm">
                            <small class="p-invalid p-error" >Las contraseñas no coinciden.</small>
                        </template>
                    </div>
					

					<!-- <label for="password1" class="block text-900 font-medium mb-2 mt-3">Password</label>
					
						<div class="p-inputgroup mb-3">
						<span class="p-inputgroup-addon">
							<i class="pi pi-lock"></i>
						</span>
						<span class="p-float-label">
							<InputText id="txtPassword" type="password" class="w-full" placeholder="Contraseña" v-model="frmUsuarios.password"/>
						</span>
						<Button icon="pi pi-eye" id="show_password" type="button" @click="mostrarPassword">
						</Button>
					</div> -->

					<div class="col-12 align-items-center">
						<div class="flex align-items-center">
							<Checkbox id="rememberme1" :binary="true" v-model="frmUsuarios.auth_notification" class="mr-2"></Checkbox>
							<label for="rememberme1">Recordarme</label>
                            
						</div>
                        <template v-if="loader==true">
                            <div class="col-12 text-center align-items-center">
                                <center>
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            </center>
                            </div>
                            
                        </template>
                        <div class="col-12 text-center">
                            <!-- <center> -->
                                
                                <Button id="buttonregistro" class="login-button mb-3 px-3 mt-3" label="Registrar" @click.prevent="registrar"></Button>
                            <!-- </center> -->
                        </div>
						<!-- <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer" @click="openModal">Olvidaste tu contraseña?</a> -->
					</div>
					
                    
				</div>
				</section>
				<Dialog header="Recuperar contraseña" v-model:visible="display" :breakpoints="{'960px': '85vw'}" :style="{width: '30vw'}" :modal="true" class="p-fluid">
                        <p>Por favor ingrese su email</p>
                        <div class="grid formgrid">
                            <div class="col-12 lg:col-12">
                                <span class="p-float-label">
                                    <InputText type="text" id="email" placeholder="Email" v-model="frmUsuarios.email"/>
                                </span>
                            </div>
                            
                        </div>
                        <template v-if="loader==true">
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            </template>
                        <template #footer>
                            <Button label="Enviar" @click="recuperarContraseña" icon="pi pi-check" class="p-button-raised p-button-primary"/>
                        </template>
                    </Dialog>
					<Toast />
			</div>
		</div>
    </div>
</template>

<script>
// import ProductService from '../../service/ProductService';
// import CarouselDoc from "./CarouselDoc";
import { reactive } from 'vue';
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import EventBus from '../event-bus';
import axios from 'axios'
export default {
    setup() {
        const state = reactive({
            name: '',
            email: '',
            password: '',
            accept: null
        });

        const rules = {
            name: { required },
            email: { required, email },
            password: { required },
            accept: { required }
        };

        const v$ = useVuelidate(rules, state);

        // new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');

        return { state, v$}
    },
	data() {
		return {
			frmUsuarios: {
                    name: '',
                    last_name: '',
                    email: '',
                    num_cel: '',
                    ciudad: '',
                    cedula: 0,
                    nit: 0,
                    razon_social: '',
                    cargo: '',
                    password: '',
                    password_confirm: '',
                    auth_notification: '',
                    terms_conditions: '',
                    nit_transporte: ''
                },
			haytoken: '',
			listUsuarios: [],
			message: [],
            zonas: [],
			registro: false,
			loader: false,
            showcamposvacios: false,
            selectipo_documento: null,
            tipos_documentos: ['Registro civil de nacimiento',
						'Tarjeta de identidad ',
						'Cédula de ciudadanía',
						'Tarjeta de extranjería',
						'Cédula de extranjería',
						'NIT',
						'Pasaporte'],
			count: 0,
			checked: '',
            email: '',
			display: false,
            submitted: false,
            submitted_email: true,
			responsiveOptions: [
				{
					breakpoint: '1024px',
					numVisible: 3,
					numScroll: 3
				},
				{
					breakpoint: '600px',
					numVisible: 2,
					numScroll: 2
				},
				{
					breakpoint: '480px',
					numVisible: 1,
					numScroll: 1
				}
			]
		}
	},
    productService: null,
	created() {
        // this.productService = new ProductService();
	},
	mounted(){
        this.loadZonas();
        this.windowWidth = screen.height;
        EventBus.emit('show-ruta');
    },
	components: {
		// 'CarouselDoc': CarouselDoc
	},
	methods: {
            loadZonas(){
                this.zonas = [];
                this.loader = true;
                var _that = this;
                axios.get('/cargar_zonas').then(response => {
                    this.zonas = response.data.data;
                    // console.log(response.data.data);
                    _that.loader = false;
                }).catch(function (error) {
                    console.log(error);
                    _that.loader = false;
                });
            },
            mostrar_mensaje_esp(message){
                console.log(this.v$.email.required.$message.replace('Value', 'Email'))
                // if (this.v$.email.required.$message.replace('Value', 'Email') == 'Email is required') {
                if (message == 'Email is required') {
                    return "Email es requerido"
                }
                if (message == 'Value is not a valid email address') {
                    return "El valor no es una dirección de correo electrónico válida"
                }
                // return this.v$.email.required.$message.replace('Value', 'Email')
            },
            registrar(){
                // console.log(this.frmUsuarios);
                this.frmUsuarios.nit_transporte = this.frmUsuarios.nit_transporte.replaceAll('.', '');
                this.frmUsuarios.email = this.v$.email.$model;
                // console.log(this.frmUsuarios.nit_transporte);
                var _that = this;
                var buttonregistro = document.getElementById('buttonregistro');
                // var nit_gen = document.getElementById('nit');
                var error = '';
                
                if(!this.frmUsuarios.email || !this.frmUsuarios.password || !this.frmUsuarios.name || !this.frmUsuarios.last_name || !this.frmUsuarios.ciudad || !this.frmUsuarios.razon_social || !this.frmUsuarios.cargo || !this.frmUsuarios.nit || this.selectipo_documento==null){
                    
                    error = 'Debe llenar todos los campos'
                    this.showError(error);
                }else{
                    if(this.frmUsuarios.password != this.frmUsuarios.password_confirm){
                       error = 'Las contraseñas no coinciden!'
                        this.showError(error); 
                    }else{
                        // this.frmUsuarios.ciudad = this.seleczona[0].nombre_division;
                        
                        buttonregistro.setAttribute("disabled", "");
                        this.loader = true;
                        axios.post('/registergenerador',{name: this.frmUsuarios.name,last_name: this.frmUsuarios.last_name,email: this.frmUsuarios.email,password: this.frmUsuarios.password, num_cel: this.frmUsuarios.num_cel,ciudad: this.frmUsuarios.ciudad,cedula: this.frmUsuarios.cedula,nit: this.frmUsuarios.nit,nit_transporte: this.frmUsuarios.nit_transporte,razon_social: this.frmUsuarios.razon_social,selectipo_documento: this.selectipo_documento,cargo: this.frmUsuarios.cargo,auth_notification: 1,terms_conditions: 1}).then((response) => {
                                _that.$toast.add({severity:'success', summary: 'Registro exitoso', detail:'favor valide su correo para activar la cuenta', life: 7000});
                                // _that.$toast.add({severity:'success', summary: 'Registro exitoso', detail:'Bienvenido, favor ingresar credenciales', life: 5000});
                                // console.log(response.data.message);
                                _that.loader = false;
                                buttonregistro.removeAttribute('disabled');
                                // _that.registro = false;
                                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
                                _that.haytoken = response.data.access_token;
                                // localStorage.setItem( 'Authorization', JSON.stringify(response.data.access_token) );
                                _that.$store.dispatch('infoNavigation/getAccess','Bearer '+response.data.access_token);
                                // axios.defaults.headers.common['Email'] = 'Bearer ' + response.data.email;
                                // localStorage.setItem( 'Email', response.data.email );
                                setTimeout( function() { _that.$router.push({ path: '/login'})},4000);
                                // _that.clearForm();
                            }).catch(function (error) {
                                if (error.response) {
                                        buttonregistro.removeAttribute('disabled');
                                    // Request made and server responded
                                    // console.log(JSON.stringify(error.response.data));
                                    // console.log(error.response.status);
                                    // console.log(error.response.headers);
                                    if(error.response.status==424 || error.response.status==430){
                                        _that.showError(JSON.stringify(error.response.data));
                                    }else{
                                        // _that.showError(error.response.data.message);
                                        _that.showError(JSON.stringify(error.response.data.message));
                                    }
                                    if(error.response.status==420){
                                        var nit_custo = document.getElementById("nit_custo");
                                        nit_custo.classList.add('p-invalid'); 
                                    }
                                    if(error.response.status==421){
                                        var nit_gene = document.getElementById("nit_gene");
                                        nit_gene.classList.add('p-invalid'); 
                                    }
                                    buttonregistro.removeAttribute('disabled');
                                    _that.loader = false;
                                    // alert(error.response.data.message);
                                    
                                    // _that.showError(error.response.data.message);
                                } else if (error.request) {
                                    _that.loader = false;
                                    buttonregistro.removeAttribute('disabled');
                                    // The request was made but no response was received
                                    // console.log(error.request);
                                } else {
                                    _that.loader = false;
                                    buttonregistro.removeAttribute('disabled');
                                    // Something happened in setting up the request that triggered an Error
                                    // console.log('Error', error.message);
                                }
                            });
                    }
                }
            },
			openRegister(){
                this.$router.push({ path: '/login' });
                // this.registro = true;
            },
			mostrarPassword(){
                var cambio = document.getElementById("txtPassword");
                var show_password;
                if(cambio.type == "password"){
                    cambio.type = "text";
                    show_password = document.querySelector("#show_password > span.pi.pi-eye.p-button-icon");
                    show_password.classList.remove('pi-eye');
                    show_password.classList.add('pi-eye-slash'); 
                }else{
                    cambio.type = "password";
                    show_password = document.querySelector("#show_password > span.pi.pi-eye-slash.p-button-icon");
                    show_password.classList.remove('pi-eye-slash');
                    show_password.classList.add('pi-eye'); 
                }
            },
            clearForm(){
                this.frmUsuarios.name = "";
                this.frmUsuarios.last_name = "";
                this.frmUsuarios.email = "";
                this.frmUsuarios.password = "";
                this.frmUsuarios.password_confirm = "";
            },
            recuperarContraseña(){
                if(!this.frmUsuarios.email){
                    this.$toast.add({severity:'error', summary: 'Error en envio', detail:'Asegurese de que su correo este correctamente escrito', life: 5000});
                }else{
                this.loader = true;
                var _that = this;
                axios.post('/passwordreset', {email: this.frmUsuarios.email}).then((response) => {
                            console.log(response.data.message);
                            _that.$toast.add({severity:'success', summary: 'Envio exitoso', detail:'por favor seguir las indicaciones que hemos enviado a su correo', life: 5000});
                            _that.loader = false;
                            // _that.registro = false;
                            _that.closeModal();
                            
                        }).catch(function (error) {
                        if (error.response) {
                        // Request made and server responded
                        // console.log(error.response.data);
                        // console.log(error.response.status);
                        // console.log(error.response.headers);
                        _that.loader = false;
                        // alert(error.response.data.message);
                        _that.showError(error.response.data.message);
                        } else if (error.request) {
                            _that.loader = false;
                        // The request was made but no response was received
                        // console.log(error.request);
                        } else {
                            _that.loader = false;
                        // Something happened in setting up the request that triggered an Error
                        // console.log('Error', error.message);
                        }
                    });
                }
            },
			openModal() {
				this.display = true;
			},
            closeModal() {
				this.display = false;
			},
            showSuccess() {
				this.$toast.add({severity:'success', summary: 'Ingreso exitoso', detail:'Bienvenido', life: 5000});
			},
            showError(error) {
                // this.message = [{severity: 'error', detail: 'Error Message', content: error.response.data.message, id: this.count++}]
                this.$toast.add({severity:'error', summary: 'Error', detail:error, life: 5000});
                this.showcamposvacios = true;
                this.submitted = true;
            },
            validarcampo(data){
                // console.log(data);
                if(!data){
                    this.showcamposvacios = true;
                    this.submitted = true;
                }
            },
            validarcampopassword(){
                if(this.frmUsuarios.password == this.frmUsuarios.password_confirm){
                    return false;
                }else{
                    return true;
                }
            }
	}
}
</script>

<style lang="scss" scoped>
.product-item {
    .product-item-content {
        border: 1px solid var(--surface-border);
        border-radius: 3px;
        margin: .3rem;
        text-align: center;
        padding: 2rem 0;
    }
    .product-image {
        width: 50%;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
    }
}
.title-register{
    color: #0A354E;
    font-size: 2.25rem;
}
.pages-body.login-page {
    /* background: url(../images/pages/tracking.png) !important; */
    /* background: url(../images/pages/login-bg.jpg); */
	background-image: url('../../public/layout/images/pages/generadores-register.jpg') !important;
    background-size: cover !important;
    overflow-y: auto;
    
    
}
@media only screen and (max-width: 1600px) {
.pages-body.login-page {
	background-image: url('../../public/layout/images/pages/generadores-register.jpg') !important;
    background-size: cover !important;
    overflow-y: auto;
    height: auto;
}
}
.multiselect{
    border: var(--ms-border-width,1px) solid #99908e;
}
.multiselect-invalid {
  border: var(--ms-border-width,1px) solid #b12000;
}

</style>

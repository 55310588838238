
import { createApp, h } from 'vue'
import { reactive } from 'vue';
import AppWrapper from './containers/Externa.vue';
// import AppWrapper from './AppWrapper.vue';
// import AppEspecial from './AppEspecial.vue';
import router from './router';
import PrimeVue from 'primevue/config/config.esm'
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Ripple from 'primevue/ripple';
import ProgressBar from 'primevue/progressbar';
import Badge from 'primevue/badge';
import BadgeDirective from "primevue/badgedirective";
// import * as VueRouter from 'vue-router';
import * as VueRouter from 'vue-router';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Breadcrumb from 'primevue/breadcrumb';
import RadioButton from 'primevue/radiobutton';
import Chart from 'primevue/chart';
import InputSwitch from 'primevue/inputswitch';
import Sidebar from 'primevue/sidebar';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import Panel from 'primevue/panel';
import Password from 'primevue/password';
import ConfirmDialog from 'primevue/confirmdialog';
import Divider from 'primevue/divider';
import Paginator from 'primevue/paginator';
import Multiselect from '@vueform/multiselect';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column'; 
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Accordion from 'primevue/accordion';
import Timeline from 'primevue/timeline';
import AccordionTab from 'primevue/accordiontab';
import Steps from 'primevue/steps';
import Toolbar from 'primevue/toolbar';
import Card from 'primevue/card';
import OverlayPanel from 'primevue/overlaypanel';
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import Tag from 'primevue/tag';
import TieredMenu from 'primevue/tieredmenu';
import Galleria from 'primevue/galleria';
import { APIENDPOINT } from './app.config.js'
// import { APIENDPOINT } from '../.env';
import EventBus from './event-bus';
import axios from 'axios'
import FileUpload from 'primevue/fileupload';
import Image from 'primevue/image';
import Calendar from 'primevue/calendar';
import Menubar from 'primevue/menubar';
import moment from 'moment';
import SpeedDial from 'primevue/speeddial';
import Api from './services/api.js'
import store from './store/index'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'primevue/resources/primevue.min.css';   	//required: PrimeVue components
import 'primeicons/primeicons.css';	 
import 'primeflex/primeflex.css';
// import './assets/demo/flags/flags.css';				    //required: PrimeIcons
import './App.scss'; 	  
window.api = new Api();
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const app = createApp({
  render () { return h(AppWrapper); }
});
app.productionTip = false
// const app = createApp(AppWrapper);
app.use(PrimeVue, { ripple: true, inputStyle: 'outlined',
menu:[
  {
    label:'Ventajas',
    // icon:'pi pi-fw pi-bolt',
      command:() => {  document.querySelector('#section_1').scrollIntoView({
          behavior: 'smooth'
      })}
    },
    {
      label:'Quiénes somos',
      // icon:'pi pi-fw pi-tag',
        command:() => {  document.querySelector('#section_3').scrollIntoView({
            behavior: 'smooth'
        })}
      },
      {
        label:'Productos',
        icon:'pi pi-fw pi-verified',
        items:[
              {
                  label:'Seway App',
                  icon:'pi pi-fw pi-mobile',
                  to: '../info-especiales',
              },
              {
                  label:'Seway ERP',
                  icon:'pi pi-fw pi-sitemap',
                  to: '../info-generadores',
              },
              {
                  label:'Seway Terceros',
                  icon:'pi pi-fw pi-users',
                  to: '../info-proveedores'
              },
        ]
      },
      {
          label:'Contactanos',
          // icon:'pi pi-fw pi-sign-in',
          command:() => {  document.querySelector('#contacto').scrollIntoView({
            behavior: 'smooth'
        })}
          
    },
      {
          label:'Iniciar sesión',
          icon:'pi pi-fw pi-sign-in',
          to: 'pages/login'
          
    },
  // {
  //   label:'Registrate',
  //   icon:'pi pi-fw pi-sitemap',
  //       to: '../pasarela',
  // }
],
locale: {
  firstDayOfWeek: 1,
  dayNames: [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado"
  ],
  dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
  dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
  monthNames: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
  ],
  monthNamesShort: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic"
  ],
  today: "Hoy",
  clear: "Borrar",
  weekHeader: "Sm",
  dateFormat: 'mm/dd/yy',
}
 });
app.use(router);
app.use(EventBus);
app.use(ToastService);
app.use(VueRouter);
app.use(moment);
app.use(store);
app.use(VueSweetalert2);

app.config.globalProperties.axios=axios;
app.config.globalProperties.$appState = reactive({ RTL: false, isNewThemeLoaded: true, layoutMode: 'light' });

axios.defaults.baseURL = APIENDPOINT;
axios.defaults.withCredentials = true;

router.beforeEach((to, from, next) => {
  
  // console.log('validar ruta que viene de '+from.path);
  // console.log('validar ruta que va a '+to.path);
  // console.log(from);
  // console.log(to);

      

      if (to.matched.some(record => record.meta.requiresAuth)) {
        
          if (to.meta.requiresAuth && store.getters['infoNavigation/access']) {
            const Authorization = 'Authorization';
            let cookie = {};
            document.cookie.split(';').forEach(function(el) {
                      let [key,value] = el.split('=');
                      cookie[key.trim()] = value;
                  })

            axios.defaults.headers.common['Authorization'] = cookie[Authorization];
              window.history.forward();
              var url = '/consulta_ingreso';
              EventBus.emit('on-loader');
              if(to.path == '/emailverify' || to.path == '/respuesta-epayco'){
                next();
              }else{
                window.api.call('post', url, {token: store.getters['infoNavigation/access']}).then( function(response) {
                  if(response.status == 200){
                      EventBus.emit('off-loader');
                      if (!store.getters['infoNavigation/access']) {
                          next({
                            path: '/login',
                          })
                        }else if (store.getters['infoNavigation/IdRol'] == 1 && to.matched[0].name != 'Especiales') {
                          next({
                              path: '/especiales',
                          })
                        }else if (store.getters['infoNavigation/IdRol'] == 2 && to.matched[0].name != 'Generador') { 
                          next({
                            path: '/generador',
                        })
                        }else if (store.getters['infoNavigation/IdRol'] == 5 && to.matched[0].name != 'EmpresaTransporte') { 
                          next({
                            path: '/empresa-transporte',
                        })
                        }else{
                          next();
                        }
                    
                      }
                }).catch(function (err) {
                  EventBus.emit('off-loader');
                    next({ name: "login" });
                    console.log(err);
                    EventBus.emit('sistemalogout');
                    store.dispatch('infoNavigation/destroyAccess',null);
                    store.dispatch('infoNavigation/destroyIdRol',null);
                    router.push({ path: '/login' });
                    return
                });
              }
              
          } else {
            if(to.path == '/emailverify' || to.path == '/respuesta-epayco'){
              next();
            }else{
              next({ name: "login" });
            }
          }
      }  else if (to.matched.some(record => record.meta.requiresVisitor)) {
        if (store.getters['infoNavigation/access']) {
          if (store.getters['infoNavigation/IdRol'] == 1) {
            next({
              path: '/especiales',
            })
          }else if (store.getters['infoNavigation/IdRol'] == 2) {
            next({
                path: '/generador',
            })
          }else if (store.getters['infoNavigation/IdRol'] == 5) {
            next({
                path: '/empresa-transporte',
            })
          }
          else {
            next()
          }
          
        } else {
          next()
        }
      } else {
        next() // make sure to always call next()!
      }
  });

app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Button', Button);
app.component('Column', Column);
app.component('InputText', InputText);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('Password', Password);
app.component('Chart', Chart);
app.component('MegaMenu', MegaMenu);
app.component('Card', Card);
app.component('Timeline', Timeline);
app.component('Steps', Steps);
app.component('Badge', Badge);
app.component('Image', Image);
app.component('Calendar', Calendar);
app.component('Toast', Toast);
app.component('DataTable', DataTable);
app.component('Breadcrumb', Breadcrumb);
app.component('Divider', Divider);
app.component('Tag', Tag);
app.component('SelectButton', SelectButton);
app.component('Menu', Menu);
app.component('TieredMenu', TieredMenu);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Menubar', Menubar);
app.component('OverlayPanel', OverlayPanel);
app.component('ScrollPanel', ScrollPanel);
app.component('Galleria', Galleria);
app.component('Paginator', Paginator);
app.component('InputSwitch', InputSwitch);
app.component('SpeedDial', SpeedDial);
app.component('Sidebar', Sidebar);
app.component('Panel', Panel);
app.component('TriStateCheckbox', TriStateCheckbox);
app.component('Dropdown', Dropdown);
app.component('Checkbox', Checkbox);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Dialog', Dialog);
app.component('Multiselect', Multiselect);
app.component('Toolbar', Toolbar);
app.component('Textarea', Textarea);
app.component('FileUpload', FileUpload);

app.use(router).mount('#app');
// app.mount('#app');
